/* @font-face {
    font-family: "Nunito Bold";
    src: url("../fonts/Nunito-Bold.ttf");
}

@font-face {
    font-family: "Nunito Regular";
    src: url("../fonts/Nunito-Regular.ttf");
}

@font-face {
    font-family: "Nunito Light";
    src: url("../fonts/Nunito-ExtraLight.ttf");
} */

/* 
@font-face {
	font-family: 'PlusJakartaDisplay Regular';
	src: url('../fonts/PlusJakartaDisplay-Regular.otf');
}

@font-face {
	font-family: 'PlusJakartaDisplay Medium';
	src: url('../fonts/PlusJakartaDisplay-Medium.otf');
}

@font-face {
	font-family: 'PlusJakartaDisplay Bold';
	src: url('../fonts/PlusJakartaDisplay-Bold.otf');
} */

@font-face {
    font-family: 'Futura Regular';
		/* src: url('../fonts/futura-medium.ttf'); */
    src: url('../fonts/futura-book-font.ttf');
    /* src: url('../fonts/futura-regular.ttf'); */
}

@font-face {
    font-family: 'Futura Medium';
    src: url('../fonts/futura-medium.ttf');
}

@font-face {
    font-family: 'Futura Bold';
    src: url('../fonts/futura-heavy.ttf');
}

:root {
    --white: #fff;
    --black: #0c0c0c;
    --darkBlue: #072753;
    --darkYellow: #ff9c00;
    --blue: #5077cf;
    --blue-bright: #4880fc;
    --theme-green: #00b69b;
    --silver: #f6f9fc;
}

body {
    font-family: 'Futura Regular';
    color: var(--darkBlue);
    font-size: 1.2rem;
    -webkit-font-smoothing: antialiased;
}

.bg-blue {
    background: var(--blue);
}

.bg-blue-bright {
    background: var(--blue-bright);
}

.bg-silver {
    background: var(--silver);
}

.nav-black {
    background-color: var(--black);
}

.nav-white {
    background-color: var(--white);
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.12);
}

.text-yellow {
    color: var(--darkYellow) !important;
}

p a {
    color: var(--color-theme-blue) !important;
}

.btn {
    border-radius: var(--rounded-small) !important;
}

.btn-lg {
    font-size: large;
    padding: 0.5rem 2rem;
}

.btn-yellow {
    background: var(--darkYellow) !important;
    color: var(--white) !important;
    transition: 0.3s;
}

.btn-yellow:hover {
    background: var(--darkBlue) !important;
    color: var(--white) !important;
}

.btn-yellow a {
    color: var(--white);
    text-decoration: none !important;
}

.btn-blue {
    background: var(--blue) !important;
    color: var(--white) !important;
    transition: 0.3s;
}

.btn-blue:hover {
    box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.12);
}

.btn-blue a {
    color: var(--white);
    text-decoration: none !important;
}

.btn-green {
    background: var(--theme-green) !important;
    color: var(--white) !important;
    transition: 0.3s;
}

.btn-green:hover {
    box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.12);
}

.btn-green a {
    color: var(--white);
    text-decoration: none !important;
}

.btn-white {
    background: var(--white) !important;
    color: var(--darkBlue) !important;
    transition: 0.3s;
}

.btn-white:hover {
    box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.12);
}

.btn-white a {
    color: var(--darkBlue);
    text-decoration: none !important;
}

.hover-white:hover {
    background: var(--white) !important;
    border-color: var(--white) !important;
    color: var(--black) !important;
}

.hover-white a:hover {
    color: var(--black) !important;
}

.hover-yellow:hover {
    background: var(--darkYellow) !important;
    border-color: var(--darkYellow) !important;
    color: var(--white) !important;
}

.btn-jumbo {
    font-size: 1.5rem;
    padding: 0.7rem 2rem;
    font-weight: bold;
}

.btn-outline-light {
    border-radius: 0 !important;
    border-width: 2px;
}

.__PrivateStripeElement {
    width: 100%;
}


/* .form-control input {
    font-size: 14px;
} */

.navbar .nav-item a {
    /* color: var(--white); */
    padding: 0 1rem;
    font-size: 1.2rem;
}

.navbar-dark .nav-item a {
    color: var(--white);
}

.navbar .navbar-nav .btn {
    font-size: 1.2rem;
}


/* .navbar-light .navbar-nav a {
    color: var(--white) !important;
} */

.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: var(--darkBlue);
}

.navbar-dark .dropdown-menu {
    background: var(--white);
    border: none;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.12);
}

.navbar-dark .dropdown-menu .dropdown-item,
.navbar-dark .dropdown-menu .dropdown-item a {
    color: var(--dark-blue-900) !important;
    text-decoration: none;
}

.navbar-dark .dropdown-menu .dropdown-item:hover {
    background: transparent;
}

.navbar .nav-item a:hover {
    /* color: var(--darkYellow); */
    text-decoration: none;
}

.section-title {
    font-size: 3.2rem;
    margin: 0;
    font-family: 'Futura Regular';
}

.section-subtitle {
    font-size: 1.3rem;
    margin: 0;
    font-family: 'Futura Regular';
    margin: 0 auto;
}

.navbar-brand a img {
    width: 100%;
    max-width: 195px;
}

.banner {
    min-height: 900px;
    position: relative;
    overflow: hidden;
    background-image: url("../images/Banner-bg.png");
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
    padding-top: 100px !important;
    padding-bottom: 50px !important;
    background-color: var(--silver);
}

.banner-small {
    min-height: auto !important;
    background-image: url("../images/SmallBanner-bg.png");
    background-color: var(--white);
}

.banner-image-box {
    width: 100%;
    margin: 0 auto;
    display: inline-block;
}

.banner h1 {
    font-family: 'Futura Bold';
    color: var(--white);
    font-size: 2.8rem;
    margin: 0;
}

.banner h2 {
    font-family: 'Futura Regular';
    font-weight: bold;
    color: var(--white);
}

.banner h5 {
    font-family: 'Futura Regular';
    color: var(--white);
}

.banner p {
    font-family: 'Futura Regular';
    color: var(--white);
}

.banner p a {
    font-family: 'Futura Regular';
    color: var(--white);
    font-weight: bold;
}

.banner-searchbar {
    position: relative;
    width: 65%;
}

.banner-searchbar input {
    padding: 1rem;
    height: auto;
    border-radius: 0;
    border: 1px solid var(--gray);
}

.banner-searchbar button {
    position: absolute;
    right: 0.5rem;
    height: calc(100% - 1rem);
    top: 50%;
    transform: translateY(-50%);
}

.banner-text-overlapping {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
}

.services h6 {
    font-family: 'Futura Regular';
    font-size: 1.2rem;
    margin-top: 1rem;
}

.services p {
    font-family: 'Futura Regular';
    color: var(--black);
}

.about-smart-apt {
    background-image: url("../images/WeAreHearToHelp.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.about-smart-apt img {
    width: 80%;
    margin: 0 auto;
}

.about-smart-apt h2 {
    font-weight: bold;
}

.about-smart-apt p {
    font-size: 1.3rem;
    line-height: normal;
    color: var(--darkBlue);
    margin-top: 1rem;
}


/* .footer {
    background-color: var(--silver);
} */

.copyright-section a {
    /* font-size: 1.2rem; */
    font-size: 1rem;
    color: var(--black);
    text-decoration: none !important;
    margin: 0 1rem;
}

.copyright-section a:first-child {
    margin-left: 0;
}

.copyright-section a:last-child {
    margin-right: 0;
}

.copyright-section a img {
    width: 35px;
    margin: 0 auto;
}

.copyright-section p {
    /* font-size: 1.2rem; */
    font-size: 1rem;
    color: var(--black);
}

.input-error {
    position: relative;
    color: var(--white);
    background-color: var(--theme-red);
    font-size: 0.8rem;
    border-radius: var(--rounded-small);
    margin-top: 5px;
    font-family: 'Futura Regular' !important;
    font-weight: bold;
    padding: 2px 10px;
    text-align: left;
}

.error-msg {
    position: relative;
    color: var(--white);
    background-color: var(--theme-red);
    font-size: 0.8rem;
    border-radius: var(--rounded-small);
    margin-top: 5px;
    font-family: 'Futura Regular' !important;
    font-weight: bold;
    padding: 2px 10px;
    text-align: center;
}

.input-error::after {
    position: absolute;
    content: '';
    left: 10px;
    top: -2px;
    background: inherit;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    z-index: -1;
}


/* Pricing Page */

.pricing-plan-tabs {
    border: 1px solid var(--dark-blue);
    background: var(--white);
    border-radius: var(--rounded-small);
    overflow: hidden;
}

.pricing-plan-tabs .nav-link {
    color: var(--dark-blue);
    font-weight: bold;
    border: none;
    border-radius: 0;
    min-width: 200px;
}

.pricing-plan-tabs .nav-link.active {
    color: var(--white);
    background-color: var(--darkBlue);
}

.pricing-plan-tabs-pane {
    color: var(--black);
}

.pricing-plan-tabs-pane .card {
    border: none;
    border-radius: var(--rounded-small);
    box-shadow: 5px 5px 8px 0 rgba(0, 0, 0, 0.12);
    display: block;
}

.pricing-plan-tabs-pane .card-title {
    font-size: 3rem;
    font-weight: bold;
    color: var(--black);
}

.pricing-plan-tabs-pane .card-text {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--black);
}

.pricing-plan-tabs-pane .btn {
    background-color: var(--theme-green);
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--white);
    width: auto;
    padding: 0.3rem 3rem;
}


/* Pricing Page Ends */


/* .sign-up-field.css-2b097c-container {
    border: 1px solid var(--gray);
    border-radius: 0.25rem;
} */


/* Tosters code */

.Toastify__toast {
    border-radius: 10px;
}

.Toastify__toast--success {
    background: var(--theme-green);
}

.Toastify__toast--warning {
    background: var(--theme-yellow);
}

.Toastify__toast--error {
    background: var(--theme-red);
}

.Toastify__toast--info {
    background: var(--color-theme-blue);
}


/* Tosters code Ends */

.map-property-card {
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    padding: 0.8rem 1.2rem;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 999;
}

@media only screen and (max-width: 1300px) {
    .banner h1,
    .section-title {
        font-size: 2.8rem;
    }
    .banner h3 {
        font-size: 1.5rem;
    }
    .about-smart-apt p {
        font-size: 1.2rem;
    }
    .banner-text-overlapping {
        transform: translate(-50%, -80%);
    }
}

@media only screen and (max-width: 1150px) {
    .banner h1,
    .section-title {
        font-size: 2.5rem;
    }
    .banner h3 {
        font-size: 1.3rem;
    }
    .banner-text-overlapping {
        transform: translate(-50%, -70%);
    }
}

@media only screen and (max-width: 1024px) {
    .banner {
        min-height: 800px;
        background-size: cover;
    }
    .banner h1,
    .section-title {
        font-size: 2.3rem;
    }
    .banner h3 {
        font-size: 1.2rem;
    }
    .banner-searchbar button {
        padding: 0 5px;
    }
}

@media only screen and (max-width: 991px) {
    .navbar-dark {
        background-color: var(--black);
    }
    .navbar .nav-item {
        text-align: center;
    }
    .navbar .nav-item a {
        padding: 1rem;
        display: inline-block;
    }
    .section-subtitle {
        max-width: 100%;
    }
    .banner-text-overlapping {
        position: relative;
        transform: none;
        left: 0;
        margin-top: 2rem;
    }
    .banner-searchbar {
        margin: 0 auto;
    }
    .banner h1,
    .banner h2 {
        color: var(--dark-blue)
    }
    .banner-small h1,
    .banner-small h2 {
        color: var(--white)
    }
}

@media only screen and (max-width: 768px) {
    .banner h1,
    .section-title {
        font-size: 1.6rem;
    }
    .banner h3 {
        font-size: 1.2rem;
    }
    .about-smart-apt h2 {
        font-size: 1.4rem;
    }
    .banner-searchbar button {
        padding: 0 2rem;
    }
}

@media only screen and (max-width: 600px) {
    .banner-searchbar {
        width: 100%;
        padding: 0 1rem;
    }
    .banner-searchbar button {
        position: relative;
        top: auto;
        right: auto;
        transform: none;
        margin-top: 1rem;
        padding: 0.5rem 1.5rem !important;
    }
}

@media only screen and (max-width: 480px) {
    .Toastify__toast {
        border-radius: 0;
        padding: 8px 12px;
    }
    .Toastify__close-button {
        align-self: center;
    }
}