/* @font-face {
	font-family: 'PlusJakartaDisplay Regular';
	src: url('../fonts/PlusJakartaDisplay-Regular.otf');
}

@font-face {
	font-family: 'PlusJakartaDisplay Medium';
	src: url('../fonts/PlusJakartaDisplay-Medium.otf');
}

@font-face {
	font-family: 'PlusJakartaDisplay Bold';
	src: url('../fonts/PlusJakartaDisplay-Bold.otf');
} */

@font-face {
    font-family: 'Futura Regular';
    src: url('../fonts/futura-book-font.ttf');
    /* src: url('../fonts/futura-regular.ttf'); */
}

@font-face {
    font-family: 'Futura Medium';
    src: url('../fonts/futura-medium.ttf');
}

@font-face {
    font-family: 'Futura Bold';
    src: url('../fonts/futura-heavy.ttf');
}

:root {
    /* colors */
    --white: #fff;
    --black: #14142B;
    --gray: #6E7191;
    --blue-shade: #F5F7FC;
    --color-theme-blue: #5077CF;
    --color-theme-green: #00B69B;
    --color-theme-red: #FF3951;
    --color-theme-yellow: #FFC226;
    --color-theme-purple: #6935FD;
    --border-color: #C2C3D4;
    /* Border Radius */
    --rounded-small: 5px;
    --rounded-medium: 15px;
    --rounded-big: 30px;
}


/* New Colors */


/* Common Classes */

body {
    font-family: 'Futura Medium';
}

.highlighted-text {
    color: var(--color-theme-blue) !important;
}

.highlighted-text.underline {
    text-decoration: underline;
}

.btn.btn-rounded {
    border-radius: var(--rounded-big) !important;
}

.bg-blue-shade {
    background-color: var(--blue-shade);
}

.py-5em {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.mb-5em {
    margin-bottom: 5rem;
}

.mt-5em {
    margin-top: 5rem;
}

.rounded-box {
    background-color: var(--white);
    border-radius: var(--rounded-big);
    padding: 1.5rem;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);
}

.img-80 {
    width: 80px;
    height: 80px;
}

.btn {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.12);
}

.btn-theme-blue {
    background: var(--color-theme-blue) !important;
    color: var(--white) !important;
    transition: 0.3s;
}

.btn-theme-blue:hover {
    box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.12);
}

.btn-red {
    background: var(--color-theme-red) !important;
    color: var(--white) !important;
    transition: 0.3s;
}

.btn-red:hover {
    box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.12);
}

.badge-theme-red {
    background-color: var(--color-theme-red);
    color: var(--white);
}

.footer p {
    color: var(--gray);
    font-family: 'Futura Regular';
}

.footer h5 {
    color: var(--black);
    font-family: 'Futura Medium';
}


/* Common Classes Ends */


/* Division Title */

.division-title {
    font-size: 2rem;
    margin: 0;
    color: var(--black);
    font-family: 'Futura Bold';
}

.division-subtitle {
    font-size: 0.9rem;
    font-family: 'Futura Regular' !important;
    color: var(--gray) !important;
    margin: 0 auto;
}


/* Division Title Ends */


/* Hero section */

.hero {
    height: 100vh;
    background-image: none !important;
    background-color: var(--white) !important;
}

.hero h1 {
    font-family: 'Futura Bold' !important;
    color: var(--black) !important;
}

.hero h6 {
    font-family: 'Futura Regular' !important;
    color: var(--gray) !important;
}


/* Hero section Ends */


/* Features Section */

.features-box .media-body {
    font-size: 1.2rem;
    font-family: 'Futura Medium' !important;
    color: var(--black) !important;
}


/* Features Section Ends */


/* Contact Section */

.contact-section h4 {
    font-size: 1.5rem;
    font-family: 'Futura Medium' !important;
    color: var(--black);
}

.contact-section ul {
    text-align: left;
}

.contact-section ul li {
    display: flex;
    font-family: 'Futura Regular' !important;
    color: var(--gray);
}

.contact-section ul li svg {
    margin-top: 3px;
    margin-right: 0.8rem;
    font-size: 20px;
}

.contact-section.red svg {
    color: var(--color-theme-red);
}

.contact-section.blue svg {
    color: var(--color-theme-blue);
}


/* Contact Section Ends */


/* Pricing Plans Section */

.pricing-box p {
    color: var(--gray);
    font-family: 'Futura Regular' !important;
    margin-bottom: 1rem;
}

.pricing-box p.red {
    color: var(--color-theme-red);
}

.pricing-box h3 {
    color: var(--black);
    font-family: 'Futura Bold' !important;
    font-size: 1.5rem;
}

.pricing-box .big-text {
    font-size: 2.8rem;
    color: var(--black);
    font-family: 'Futura Bold' !important;
}

.pricing-box h6 {
    color: var(--black);
    font-family: 'Futura Regular' !important;
}

.terms-check {
	letter-spacing: 0.32px;
}

.phoneNo .special-label {
	display: none;
}

.inputFields .form-control,
.form-group .default-select__control {
	font-size: 0.9rem;
}

.react-tel-input .form-control {
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding-top: 0.375rem;
	padding-bottom: 0.375rem;
}

.big-text small {
	font-size: large;
}

.recommendation-note p {
	padding: 1rem;
	background: var(--silver);
	border: 1px solid var(--gray-300);
	border-radius: var(--rounded-small);
	font-size: 0.82rem;
}

/* Pricing Plans Section Ends */

@media only screen and (min-width: 1260px) {
    .custom-container.container {
        max-width: 1260px;
    }
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
    display: block !important;
}

.specials-box{
	background-color: #f7fffd;
	border: 1px solid #00b69b;
	padding-top: 1rem;
	padding-bottom: 1rem;
	padding-right: 1rem;
	padding-left: 1rem;
	border-radius: var(--rounded-big);
	font-size: 1rem;
	color: black
}

.gmap-container {
	position: relative;
}

.gmap-button {
	position: absolute;
	top: 5px;
	right: 5px;
	z-index: 500;
}

.gmap-reset-button {
	position: absolute;
	top: 5px;
	right: 80px;
	z-index: 500;
}

.copy-clipboard {
	width: 75%;
	padding-left: 10px;
	border: 1px solid var(--gray-300);
	border-radius: 7px;
	cursor: pointer;
}

.pointer {
	cursor: pointer;
}

.copy-clipboard-button {
	border-radius: 10px;
	margin-left: 5px;
}

.notification-table table {
	border: none !important;
}

.user-otp-input {
  display: flex;
  justify-content: center;
}

.otp-input {
  margin: 0px;
  height: 35px;
  width: 2.5rem !important;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: 1.2rem;
  background: #eef2f3;
}

.otp-input:focus {
  box-shadow: 0 0 5pt 2pt var(--gray-300);
  outline-width: 0px;
}

.inline-input {
  border: 0 !important;
	outline: 0 !important;
	font-size: 1.1rem !important;
	padding: 0 !important;
	margin: 0;
}

.inline-input:focus {
	border: 0;
  outline: 0;
	font-size: 1.13rem !important;
  border-bottom: 1px solid var(--color-theme-blue) !important;
	width: 100%;
	transition: 0.15s;
}

.inline-label {
	font-size: 1.2rem;
}

.inline-form-label {
	color: var(--color-theme-blue);
	font-size: .8rem;
	margin-bottom: 0;
}

.no-border {
	border: 0 !important;
	outline: 0 !important;
}

.notification-type-select option {
	font-size: large;
}

.Toastify__toast--default {
	color: #ffffff;
}

.Toastify__close-button--default {
	color: #ffffff;
}


.required > label:after {
	content: " *";
	color: #FF3951;
}

.kanban-container::-webkit-scrollbar {
  display: none;
}

.kanban-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
	overflow-x: auto;
}

.agent-profile-container a {
	color: var(--gray-500) !important;
  text-decoration: none !important;
}

.kanban-toggle-button {
	display: flex;
	justify-content: center;
}

@media only screen and (max-width: 767px) {
	.kanban-toggle-button {
		justify-content: left;
	}
}

@media (min-width:320px)  { 
	.roommates-popover .popover {
		min-width: 350px;
		width: 100% !important;
	}
}
@media (min-width:700px)  { 
	.roommates-popover .popover {
		min-width: 500px;
		width: 100% !important;
	}
}
@media (min-width:961px)  { 
	.roommates-popover .popover {
		min-width: 500px;
		width: 30% !important;
	}
}