/* @font-face {
  font-family: 'Nunito Bold';
  src: url('../fonts/Nunito-Bold.ttf');
}

@font-face {
  font-family: 'Nunito Regular';
  src: url('../fonts/Nunito-Regular.ttf');
}

@font-face {
  font-family: 'Nunito Light';
  src: url('../fonts/Nunito-ExtraLight.ttf');
} */

/* @font-face {
	font-family: 'PlusJakartaDisplay Regular';
	src: url('../fonts/PlusJakartaDisplay-Regular.otf');
}

@font-face {
	font-family: 'PlusJakartaDisplay Medium';
	src: url('../fonts/PlusJakartaDisplay-Medium.otf');
}

@font-face {
	font-family: 'PlusJakartaDisplay Bold';
	src: url('../fonts/PlusJakartaDisplay-Bold.otf');
} */

@font-face {
    font-family: 'Futura Regular';
    src: url('../fonts/futura-book-font.ttf');
    /* src: url('../fonts/futura-regular.ttf'); */
}

@font-face {
    font-family: 'Futura Medium';
    src: url('../fonts/futura-medium.ttf');
}

@font-face {
    font-family: 'Futura Bold';
    src: url('../fonts/futura-heavy.ttf');
}

:root {
    /* new colors */
    /* Blues */
    /* color: #113569; */
    /* color: #bbcae1; */
    /* Neutrals */
    /* color: #eae8e9; */
    /* color: #e6cab2; */
    /* color: #d3c3bb; */
    /* color: #e0cdd9; */
    /* colors */
    --white: #fff;
    --black: #0c0c0c;
    --gray-100: #d2d9e9;
    --gray-300: #a8b0cb;
    --gray-500: #8696b7;
    --gray-700: #596782;
    --gray-800: #37445b;
    --gray-900: #1e2635;
    --highlight: #fcf6e9;
    --theme-red: #fd5454;
    --theme-green: #00b69b;
    --theme-blue: #4880ff;
    --theme-yellow: #fcbe2d;
    /* Border Radius */
    --rounded-small: 5px;
    --rounded-medium: 15px;
    --rounded-big: 30px;
		--silver-50: #E3EDF6;
}


/* New Colors */

.color-red {
    color: var(--theme-red);
}

.color-green {
    color: var(--theme-green);
}

.color-blue {
    color: var(--theme-blue);
}

.color-yellow {
    color: var(--theme-yellow);
}

.color-purple {
    color: #5e72e4;
}

.color-orange {
    color: #fb6340;
}

.color-skyblue {
    color: #11cdef;
}

.color-pink {
    color: #f3a4b5;
}

@media print {
    html,
    body {
        display: none;
    }
}

body {
    font-family: 'Futura Regular' !important;
    color: var(--gray-900) !important;
    font-size: 0.92rem !important;
    font-weight: 400;
    /* letter-spacing:-0.50px; */
    /* -webkit-font-smoothing: subpixel-antialiased !important;  */
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased;
}

a {
    cursor: pointer;
}

.my-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
}

.cursor-pointer {
    cursor: pointer;
}

.filler {
    margin-left: 4px;
    margin-right: 4px;
}

.bg-light-purple {
    background: var(--light-purple);
}

.bg-light-gray {
    background: var(--light-gray);
}

.rounded-big {
    border-radius: var(--rounded-big) !important;
    overflow: hidden;
}

.rounded-medium {
    border-radius: var(--rounded-medium) !important;
    overflow: hidden;
}

.rounded-small {
    border-radius: var(--rounded-small) !important;
    overflow: hidden;
}

.box-shadow-none {
    box-shadow: none !important;
}

.pl-245 {
    padding-left: 245px !important;
    transition: 0.3s;
}


/* map checkbox css */


/* Toggle Button */

.cm-toggle {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    border: 0;
    outline: 0;
    cursor: pointer;
    margin: 0 10px;
}


/* To create surface of toggle button */

.cm-toggle:after {
    content: '';
    width: 50px;
    height: 10px;
    display: inline-block;
    background: rgba(196, 195, 195, 0.55);
    border-radius: 18px;
    clear: both;
}


/* Contents before checkbox to create toggle handle */

.cm-toggle:before {
    content: '';
    width: 25px;
    height: 25px;
    display: block;
    position: absolute;
    left: 0;
    top: -2px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
}


/* Shift the handle to left on check event */

.cm-toggle:checked:before {
    left: 25px;
    box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.6);
}


/* Background color when toggle button will be active */

.cm-toggle:checked:after {
    background: var(--gray-300);
}

.cm-toggle,
.cm-toggle:before,
.cm-toggle:after,
.cm-toggle:checked:before,
.cm-toggle:checked:after {
    transition: ease 0.3s;
    -webkit-transition: ease 0.3s;
    -moz-transition: ease 0.3s;
    -o-transition: ease 0.3s;
}


/* Header Start */

.admin-header {
    background: var(--white);
    height: 65px;
}

.admin-header .navbar-brand {
    cursor: pointer;
}

.admin-header .nav-item a {
    font-size: 1.1rem !important;
}

.admin-header input {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background: transparent;
    color: var(--white);
}

.admin-header,
.admin-header .navbar-dark {
    background: var(--gray-900);
}


/* .admin-header svg path {
    fill: var(--gray);
} */

.admin-header select {
    -webkit-appearance: none;
    border: 0;
    background: var(--white);
    border-bottom: 1px solid var(--dark-gray);
    border-radius: 0;
    box-shadow: none !important;
}

.admin-header select option {
    color: var(--dark-gray);
}

.admin-header .navbar .nav-item a {
    font-size: 1.3rem;
    padding: 0.2rem 1.2rem;
    font-weight: 300;
}

.admin-header .navbar .nav-item a.active {
    background: var(--white);
    color: var(--gray-900);
    border-radius: var(--rounded-big);
}

.admin-header .navbar-dark .dropdown-menu {
    top: 55px;
    right: -30px;
    border-radius: 0;
}

.admin-header .navbar-dark .dropdown-menu .dropdown-item {
    padding: 0.5rem 1.5rem;
    font-weight: 400;
    color: var(--gray-900) !important;
}

.admin-header .navbar-dark .dropdown-menu .dropdown-item a {
    font-weight: 400;
    background: var(--white);
}

.apartment-area label svg {
    /* .filters svg  */
    width: 20px;
    height: 20px;
}

.dropdown-item a {
    padding: 0 !important;
    display: inline-block;
}

.navbar-logo {
    color: var(--white) !important;
    font-weight: bold;
}

.dashboard-logo {
    width: 32px;
}

.navbar-brand {
    /* font-size: 26px !important; */
    padding: 3px 0 !important;
    margin-right: 0 !important;
}

.btn.sidebar-button,
.btn.sidebar-button:hover,
.btn.sidebar-button:focus {
    position: fixed;
    left: 0;
    top: 5.2rem;
    padding: 0.8rem 1.1rem !important;
    border-radius: 0 10px 10px 0 !important;
    background-color: var(--gray-700);
    font-size: 16px;
    width: 54px;
    height: 52px;
    z-index: 1000;
    border: 0;
    transition: 0.3s;
}

.btn.sidebar-button:focus {
    box-shadow: none;
    /* box-shadow: 5px 0 5px 0 rgba(0, 0, 0, 0.12); */
}

.btn.sidebar-button.left-positioned {
    left: 450px;
}

.sidebar {
    width: 450px;
    height: calc(100% - 65px);
    background: var(--gray-700);
    position: fixed;
    left: -450px;
    bottom: 0;
    transition: 0.3s;
    z-index: 999;
    border: 0;
    padding: 1rem;
    overflow-y: auto;
    /* box-shadow: 5px 0 5px 0 rgba(0, 0, 0, 0.12); */
    /* opacity: 0; */
}

.backdrop,
.backdrop-small {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    /* backdrop-filter: blur(5px); */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
}

.backdrop.transparent {
    opacity: 0;
}

.backdrop-small {
    height: calc(100% - 164px);
    top: auto;
    bottom: 0;
    opacity: 0;
    z-index: 1;
}

.close-sidebar-btn .btn-link {
    color: var(--white);
}

.sidebar.open {
    left: 0;
    opacity: 1;
}

.sidebar .navbar-nav a {
    width: 100%;
    padding: 10px !important;
    display: inline-block;
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.5);
    font-size: 1rem !important;
    position: relative;
}

.sidebar .navbar-nav a:hover {
    color: rgba(0, 0, 0, 1);
}

.sidebar .navbar-nav a.active {
    color: var(--white);
    background-color: var(--blue-bright);
    border-radius: var(--rounded-small);
}

.sidebar .navbar-nav a.active>svg {
    color: var(--white);
}

.navbar-nav .dropdown-item svg {
    width: 22px !important;
}

.sidebar .navbar-nav a.active::before {
    content: '';
    position: absolute;
    width: 4px;
    height: 100%;
    left: -16px;
    top: 0;
    background: var(--blue-bright);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.notification_bar {
    width: 350px;
    height: 400px;
    background: var(--white);
    position: fixed;
    right: 1rem;
    top: -101%;
    border: 1px solid var(--gray-300);
    transition: 0.3s;
    z-index: 1001;
    border-radius: var(--rounded-small);
    overflow: hidden auto;
}

.notification_bar.open {
    top: 70px;
}


/* .notification_bar ul li {
    padding: 0 10px;
    padding-bottom: 10px;
} */

.notification_bar ul h5 {
    background: var(--theme-green);
    padding: 5px 10px;
}

.notification_bar ul li a {
    border-bottom: 1px solid #e0e0e0;
    /* border-radius: var(--rounded-small); */
    display: inline-block;
    width: 100%;
    text-decoration: none;
    padding: 10px;
    transition: 0.3s;
}

.notification_bar ul li a:hover {
    background: var(--light-gray);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
    background: linear-gradient(87deg, #ffffff, #f5f5f5);
}

.notification_bar ul li a h6 {
    color: var(--black);
    font-weight: bold;
}

.notification_bar ul li a p {
    margin-bottom: 0;
    color: var(--black);
    font-size: 14px;
}

.notification_bar ul li a p small {
    color: var(--black);
    text-align: right;
    width: 100%;
    display: inline-block;
    font-weight: bold;
}

.close-notification-btn .btn-link {
    color: var(--black);
}

.notification-box.card {
    border-radius: var(--rounded-small);
    border-color: var(--gray-300);
    margin-bottom: 1rem;
}

.notification-box.card .card-body {
    padding: 1rem;
}

.notification-box.card .card-body p {
    color: var(--gray-700);
}

.notification-box.card .card-body p.disabled {
    color: var(--gray-500);
    font-size: 15px;
}

.flex-break {
    height: 0px;
    /* display: flex;
    flex-basis: 100%; */
}

.search-button {
    margin: 8px;
    /* padding: 0.3rem 1rem; */
    border-radius: 80px;
    /* border: 1px solid var(--gray-500); */
    color: var(--blue-900);
    width: auto;
    min-width: 50px;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.search-button>Button {
    outline: none;
    border: none;
}

.filter-bar .search-bar {
    max-width: 500px;
}

.search-bar {
    padding: 0.3rem 1rem;
    border-radius: 80px;
    border: 1px solid var(--gray-300);
    color: var(--blue-900);
    width: 370px;
    display: flex;
    overflow: hidden;
    flex: 1.5 0 0%;
    align-items: center;
    transition: 0.2s linear;
}

.filter-bar .search-bar {
    background-color: #fcfcfc;
    margin: 8px;
}

.search-bar:hover {
    border: 1px solid var(--gray-700);
    background-color: var(--white);
}

.filter-bar .search-bar:focus-within {
    border: 1px solid var(--gray-700);
    background-color: var(--white);
    max-width: 450px;
    /* width: 350px; */
}

.filter-bar .search-bar input {
    /* width: 90% !important; */
}

.search-bar input {
    border: none;
    font-size: 1.2rem;
    color: var(--blue-900);
    box-shadow: none;
    outline: none;
    /* min-width: 120px; */
    display: inline-flex;
    flex: 1 0 0%;
    /* background-color: #999; */
}

.apartment-area {
    display: flex;
    align-items: center;
}

.apartment-area label {
    color: var(--gray-900);
    font-size: 1.2rem;
}

.apartment-area .css-2b097c-container {
    display: inline-block;
    width: 150px;
    /* border: 1px solid var(--gray-500); */
    border-radius: 50px;
    /* overflow: hidden; */
    font-size: 1.2rem;
}

.apartment-area .css-yk16xz-control,
.apartment-area .css-1pahdxg-control {
    border-radius: var(--rounded-big) !important;
}


/* Header Ends */


/* .filters {
    border-radius: 10px;
} */

.filters p {
    /* color: var(--white); */
    margin-bottom: 0.5rem;
}

.filters input {
    display: inline-block;
    width: 100%;
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid var(--gray-300);
    border-radius: var(--rounded-small);
    padding: 0.375rem 0.75rem;
    background: var(--white);
}

.filters select {
    /* -webkit-appearance: none; */
    border: 0;
    background: var(--white);
    border-radius: var(--rounded-small);
    box-shadow: none !important;
    width: 100%;
    display: inline-block;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-family: 'Futura Regular';
    outline: none !important;
    padding: 0.375rem 0.75rem;
    border: 1px solid var(--gray);
}

.custom-range-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 4px !important;
    background: var(--white);
    outline: none;
    border: none !important;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    padding: 0 !important;
}

.custom-range-slider:hover {
    opacity: 1;
}

.custom-range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 22px;
    height: 22px;
    background: var(--theme-blue);
    border: 2px solid var(--theme-blue);
    border-radius: 50%;
    cursor: pointer;
}

.custom-range-slider::-moz-range-thumb {
    width: 22px;
    height: 22px;
    background: var(--theme-blue);
    border: 2px solid var(--theme-blue);
    border-radius: 50%;
    cursor: pointer;
}

.apartment-table table {
    border-collapse: separate;
    border-spacing: 0;
    background-color: var(--white);
    border-radius: var(--rounded-medium);
    margin: 0;
    overflow: hidden;
    border: 1px solid var(--silver);
}

.apartment-table table th svg {
    color: var(--white);
}

.apartment-table table,
.apartment-table table a,
.apartment-table table svg {
    color: var(--gray-900);
}

.apartment-table .table thead {
    color: var(--white);
    background-color: var(--gray-700);
}

.apartment-table .table thead th {
    height: 50px;
    vertical-align: middle;
    border-top: 0;
    font-weight: 300;
}

.apartment-table .table tbody tr th {
    background-color: var(--gray-700);
    color: var(--white);
    text-transform: uppercase;
    vertical-align: bottom;
}

.apartment-table .table tbody tr {
    border-bottom: 1px solid var(--gray-300);
}

.apartment-table .table tbody tr:hover,
.apartment-table .table tbody tr.selected-row {
    background-color: var(--silver);
    /* box-shadow: inset 1px 0 0 0px var(--gray-300), inset -1px 0 0 0px var(--gray-300); */
}


/* .apartment-table table td {
    padding: 0;
    border-left: 1px solid var(--gray);
    border-right: 1px solid var(--gray);
    border-collapse: collapse;
} */

.apartment-table table td {
    border-collapse: collapse;
    font-size: 1rem;
    padding: 1rem 0.5rem;
}


/* .apartment-table table td:nth-child(3n),
.apartment-table .table tbody th:nth-child(3n) {
    border-right: 1px solid var(--gray);
} */

.apartment-table table td.border-right-true,
.apartment-table .table tbody th.border-right-true {
    border-right: 1px solid var(--gray-300);
}

.apartment-table .min-w-900,
.client-list.min-w-900 {
    min-width: 900px;
}


/* .apartment-table table td:first-child {
    border-left: none;
} */

.apartment-table table td:last-child,
.apartment-table .table tbody th:last-child {
    border-right: none !important;
}

.apartment-table table td>table td {
    border: none;
}

.apartment-table table th,
.apartment-table table td>table td {
    font-size: 1rem;
    padding: 1rem 0.5rem;
}

.apartment-table table th:first-child,
.apartment-table table td>table td:first-child,
.apartment-table table td:first-child {
    padding-left: 1.5rem;
}

.apartment-table table th:last-child,
.apartment-table table td>table td:last-child,
.apartment-table table td:last-child {
    padding-right: 1.5rem;
}

.table-tabs .card {
    border-radius: var(--rounded-medium);
    border-top-left-radius: 0;
}

.select-plan .form-check {
    padding: 0;
    text-align: center;
}

.select-plan .form-check-label {
    width: 100%;
    padding: 0.8rem;
    position: relative;
}

.select-plan .form-check-input {
    margin: 0;
    position: relative;
}

.css-2b097c-container {
    display: inline-block;
    /* width: 150px; */
    /* border: 1px solid transparent; */
    min-width: 80px;
}

.multi-select-client.css-2b097c-container {
    border-color: var(--gray);
    border-radius: var(--rounded-small);
    background: var(--white);
}

.css-yk16xz-control,
.css-1pahdxg-control,
.sign-up-field .css-yk16xz-control {
    border-radius: var(--rounded-small) !important;
    min-height: auto;
    /* padding: 0.375rem 0.75rem; */
    padding: 0.2rem 0.75rem;
    box-shadow: none !important;
    border: 1px solid var(--gray-300) !important;
}

.sign-up-field .css-yk16xz-control {
    border-radius: 3px !important;
}

.multi-select-client .css-yk16xz-control {
    background: transparent;
}

.css-b8ldur-Input,
.css-g1d714-ValueContainer,
.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
    padding: 0 !important;
}

.css-1wa3eu0-placeholder {
    color: var(--gray-900) !important;
}

.relative-select .css-1wa3eu0-placeholder,
.relative-select .css-1uccc91-singleValue {
    position: relative;
    transform: none;
    top: 0;
}

.relative-select .css-1uccc91-singleValue {
    max-width: 100%;
}

.rounded .css-yk16xz-control,
.rounded .css-1pahdxg-control {
    border-radius: var(--rounded-big) !important;
}

.select-lg .css-yk16xz-control,
.select-lg .css-1pahdxg-control {
    font-size: 1rem;
    padding: 0.5rem 0.75rem;
}

.sign-up-field {
    border: none;
}

.css-1okebmr-indicatorSeparator {
    display: none;
}


/* Change password modal Starts */

.change-password-modal .modal-content {
    border-radius: var(--rounded-medium);
    border: none;
    overflow: hidden;
    color: var(--white);
}

.change-password-modal .modal-content .close {
    color: var(--white);
    opacity: 1;
}

.change-password-modal .form-control {
    display: inline-block;
    /* width: 100%; */
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid var(--gray-300);
    border-radius: var(--rounded-small);
    padding: 0.375rem 0.75rem;
    background: var(--white);
}

.change-password-modal .modal-header {
    background-color: var(--gray-700);
}

.payment-method .modal-content {
	border-radius: var(--rounded-medium);
	border: none;
	overflow: hidden;
}

.payment-method .modal-header {
    background-color: var(--gray-700);
		color: var(--white);
}

.payment-method .modal-content .close {
	color: var(--white);
	opacity: 1;
}

.payment-method-card {
	border-radius: var(--rounded-medium) !important;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	background-color: #343333 !important;
	color: var(--white);
	margin-right: 3rem;
	margin-left: 3rem;
	min-width: 10rem !important;
}

.modal-header,
.modal-body,
.modal-footer {
    padding: 1rem 1.3rem !important;
}


/* Change password modal Ends */


/* Feedback Form Modal Start */

.feedback-btn {
    padding: 1rem;
    border-radius: 50%;
    position: fixed;
    right: 2rem;

    /* left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%); */

    bottom: 1rem;
    background: var(--theme-green);
    color: var(--white);
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 998;
}

.feedback-modal {
    position: fixed;
    bottom: 5rem;
    right: -110%;
    z-index: 998;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    -ms-transition: 0.3s;

    width: 70%;
}

.feedback-modal .card {
    border-radius: var(--rounded-medium) !important;
    box-shadow: 0 0 8px 5px rgba(0, 0, 0, 0.06);
}

.feedback-modal.open {
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
}


/* Feedback Form Modal Ends */


/* Custom Radio Button */

.select-plan .form-check-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: transparent;
    /* border-radius: 0; */
    z-index: 0;
    border-radius: var(--rounded-medium);
    /* border: 1px solid var(--gray); */
    transition: 0.2s;
    cursor: pointer;
}

.select-plan .form-check-label:hover input~.checkmark {
    /* border-color: var(--dark-gray); */
    box-shadow: 0px 0px 15px #888888;
}

.select-plan .form-check-label input:checked~.checkmark {
    border: 3px solid var(--gray-700);
    box-shadow: 0px 0px 15px #555555;
}


/* Custom Radio Button code ends */

.btn.button-dashboard {
    border: none !important;
    border-radius: 50px !important;
    /* font-size: 1.1rem !important; */
    font-size: 1.1rem;
    color: var(--white) !important;
    padding: 0.5rem 1rem;
    transition: 0.3s;
}

.btn.button-dashboard.btn-primary {
    background-color: var(--blue-bright) !important;
}

.btn.button-dashboard.btn-green {
    background-color: var(--theme-green) !important;
}

.btn.button-dashboard.btn-red {
    background-color: var(--theme-red) !important;
}

.btn.button-dashboard:hover {
    box-shadow: inset 0 0 100px rgba(255, 255, 255, 0.15);
}

.btn-sm {
    padding: 0.25rem 0.7rem !important;
    font-size: 0.875rem !important;
    line-height: 1.5 !important;
    border-radius: var(--rounded-small) !important;
}


/* User Table */

.client-list.table {
    border-collapse: separate;
    border-spacing: 0;
    background-color: var(--white);
    border-radius: var(--rounded-medium);
    margin: 0;
    overflow: hidden;
    border: 1px solid var(--gray-300);
    font-size: 1rem;
}

.client-list thead tr {
    background-color: var(--gray-700) !important;
    position: relative;
}

.client-list thead tr th {
    font-family: 'Futura Regular';
    color: var(--white);
    border-top: 0;
}

.client-list tbody tr {
    transition: 0.3s;
}

.client-list tbody tr:hover,
.client-list tbody tr.selected-row {
    background-color: var(--silver-50);
}

.client-list tbody tr td {
    vertical-align: middle;
    padding: 0.75rem;
    color: var(--gray-900);
}

.client-list thead tr th:first-child,
.client-list tbody tr td:first-child {
    padding-left: 1.5rem;
}

.client-list thead tr th:last-child,
.client-list tbody tr td:last-child {
    padding-right: 1.5rem;
}


/* User Table Ends */

.resize-none {
    resize: none;
}

input[type='file'] {
    width: 400px;
    height: 25px;
    opacity: 0;
}

.image-file {
    width: 629px;
    height: 37px;
    background-color: white;
    box-shadow: 1px 2px 3px #ededed;
    position: relative;
    border: 1px solid #d8d8d8;
}

#val {
    cursor: pointer;
    width: 629px;
    height: 37px;
    position: absolute;
    top: 5px;
    left: 45px;
    font-size: 15px;
    line-height: 25px;
    text-indent: 10px;
}


/* map css */

.pin .pin-icon {
    font-size: 35px;
    color: red;
    cursor: pointer;
}

.property-delete {
    cursor: pointer;
}

.property-links a:link,
a:visited {
    text-decoration: none;
    color: var(--dark-gray);
}

.map-property {
    max-width: 350px !important;
}

.property-report-modal {
    max-width: 700px !important;
}

.map-property .modal-content {
    border-radius: 10px;
}

.map-property h6 {
    color: var(--black);
    font-family: 'Futura Regular';
}

.map-property p,
.map-property a {
    font-size: 14px;
    color: var(--black);
}

.map-property a {
    color: var(--blue);
}

.close-map-property {
    float: right;
    cursor: pointer;
    color: var(--black) !important;
}

.sort-table th.ascending::after,
.sort-table th span.ascending::after {
    content: '';
    /* display: inline-block; */
    margin-left: 3px;
    position: relative;
    top: 9px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid #555;
}

.sort-table th.descending::after,
.sort-table th span.descending::after {
    content: '';
    /* display: inline-block; */
    margin-left: 3px;
    position: relative;
    bottom: 13px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid #555;
}

.sort-table th {
    cursor: pointer;
}


/* Home Table css */


/* .container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
    max-width: 1350px !important;
} */

table .edit-property {
    cursor: pointer;
}


/* Pagination code */

.pagination {
    border: none;
    float: right;
    background: var(--white);
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.06);
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: var(--rounded-big) !important;
    margin-bottom: 0 !important;
    /* margin-top: 2rem; */
    /* right: 50%;
    position: relative;
    transform: translateX(50%); */
}

.pagination li {
    display: inline-block;
    margin: 0 4px;
    border-radius: 50%;
    overflow: hidden;
}

.pagination li a {
    font-size: 14px;
    display: inline-block;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination li:hover,
.pagination li a:hover,
.pagination li.active {
    background: var(--gray-700);
    color: var(--white);
    border-color: var(--gray-700);
}


/* Pagination code */

.footer-branding {
    max-width: 195px !important;
}

.react-datepicker-wrapper {
    width: 100%;
}

.input-group-text {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}


/* Property Report Code */

.property-report-header a {
    color: var(--black) !important;
    border: 1px solid var(--gray-300);
    cursor: pointer;
    text-decoration: none !important;
    padding: 8px;
    border-radius: var(--rounded-small);
    display: inline-block;
}

.property-report-header a.active {
    background-color: var(--theme-green);
    border-color: var(--theme-green);
    color: var(--white) !important;
}

.property-report-header h3 {
    margin-bottom: 0.5rem;
}

.property-report-header p {
    color: var(--gray-500);
    font-size: 1.2rem;
    margin-bottom: 0;
}

.number-of-bedroom {
    background-color: var(--silver);
    border-radius: var(--rounded-small);
}

.number-of-bedroom .form-check {
    padding: 0;
    text-align: center;
}

.number-of-bedroom .form-check-label {
    position: relative;
    padding: 0.6rem 0;
    z-index: 1;
    color: var(--black);
    width: 100%;
}

.number-of-bedroom .form-check-input {
    margin: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    appearance: none;
    outline: none;
    border: none;
}

.number-of-bedroom .form-check-input:checked::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: var(--theme-blue);
    width: 100%;
    height: 100%;
    border-radius: var(--rounded-small);
    opacity: 0.3;
}

.building-year p {
    padding: 0.3rem 1.2rem;
    display: inline-block;
    width: auto;
    margin: 0 auto;
    background: var(--white);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
    border: 1px solid var(--gray);
    border-radius: var(--rounded-small);
    margin-bottom: 1rem;
}

.property-list a {
    text-decoration: none !important;
    color: var(--black);
}

.property-list .card {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
}

.property-card-image {
    width: 100%;
    display: inline-block;
    height: 235px;
    overflow: hidden;
}

.property-report-detail-image {
    position: relative;
    width: 100%;
    display: inline-block;
    height: 500px;
    overflow: hidden;
}

.property-report-detail-image img {
    width: auto;
    min-width: 100%;
    height: auto;
    min-height: 100%;
}

.property-report-banner-description {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    color: var(--white);
}

.back-btn,
.bookmark-btn {
    color: var(--white) !important;
}

.bg-semi-transparent {
    background-color: rgba(0, 0, 0, 0.5);
}

.map-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 22px;
    background-color: var(--white);
    text-align: center;
    padding-top: 12px;
}

.map-icon a {
    color: var(--primary) !important;
}

.copy-email {
    cursor: pointer;
}

.property-report-banner-description ul li {
    display: inline-block;
    margin-right: 1rem;
}

.property-report-banner-description h2 {
    font-weight: bold;
}

.jumbo-btn.btn {
    width: 100%;
    max-width: 200px;
    border-radius: 10px !important;
    padding: 1rem 0 !important;
    font-size: 1rem !important;
    color: var(--black) !important;
    font-weight: bold !important;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.06);
    border: none;
}

.jumbo-btn.green {
    background-color: #86ffb0 !important;
}

.jumbo-btn.red {
    background-color: #ff8694 !important;
}


/* Property Report Code Ends */


/* Pricing Plan Code */

.pricing-plan-box {
    background: var(--white);
    border-radius: var(--rounded-medium);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
    text-align: center;
    padding: 1.5rem 0;
}

.pricing-plan-box.month-plan {
    background: rgba(72, 128, 255, 0.2);
}

.pricing-plan-box.year-plan {
    background: rgba(0, 182, 155, 0.2);
}

.pricing-plan-box h2 {
    margin: 0;
    color: var(--gray-900);
    font-size: 1.5rem;
}

.pricing-plan-box .big-text {
    width: 100%;
    display: inline-block;
    font-size: 2.6rem;
    font-weight: bold;
    padding: 2rem 0 2.5rem 0;
    /* border-top-left-radius: 15px;
    border-top-right-radius: 15px; */
}

.month-plan .big-text {
    color: var(--theme-blue);
}

.year-plan .big-text {
    color: var(--theme-green);
}

.pricing-plan-box .btn {
    background: var(--theme-blue);
    border-radius: 50px !important;
    border: none;
    padding: 0.7rem 2.5rem;
    font-size: 1.2rem;
    font-weight: bold;
}

.pricing-plan-box .btn:hover {
    background: var(--theme-blue);
}

.pricing-plan-box.green h2,
.pricing-plan-box.green .btn {
    background: var(--theme-green);
}

.pricing-plan-box.green .big-text {
    color: var(--theme-green);
}

.pricing-plan-box.green .btn:hover {
    background: var(--theme-green);
}


/* Pricing Plan Code Ends */


/* Privacy and Terms */

.custom-bullets {
    list-style: none;
}

.custom-bullets li {
    position: relative;
}

.custom-bullets li::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    left: -25px;
    top: 7px;
    background: var(--theme-blue);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    -ms-border-radius: 50%;
    display: inline-block;
}


/* Privacy and Terms Ends */

.filters-box {
    padding: 1rem;
    border: 1px solid var(--gray);
    border-radius: 10px;
    text-align: center;
    margin-bottom: 1rem;
    width: 100%;
    height: 100%;
    display: inline-block;
}

.filters-box input {
    text-align: center;
}

.client-select-stage select {
    appearance: none;
    background: var(--white);
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.subs-filter select {
    -webkit-appearance: none;
    border: 0;
    background: transparent !important;
    border-radius: 0;
    box-shadow: none !important;
    width: auto;
    display: inline-block;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-family: 'Futura Regular';
    outline: none !important;
}

.loader {
    position: fixed;
    /* position: relative; */
    z-index: 999;
    overflow: show;
    margin: auto;
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translateX(-50%); */
    /* transform: translateY(-50%); */
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.client-select-stage {
    position: relative;
    display: inline-block;
}

.client-select-stage::after {
    content: '';
    position: absolute;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid var(--dark-gray);
    top: calc(50% - 3px);
    right: 0;
}


/* .spark-modal-backdrop.show {
  opacity: 1 !important;
}

.spark-modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5) !important;
} */

.modal-backdrop.show {
    opacity: 1 !important;
}

.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.nav-small.navbar-logo {
    width: auto !important;
    transition: 0.5s;
}

.nav-small .brand-name {
    display: none;
}

.theme-card.card {
    border-radius: var(--rounded-medium);
    overflow: hidden;
}

.theme-card.card .card-header {
    background-color: var(--gray-700);
    color: var(--white);
    font-size: 1.2rem;
    font-weight: 700;
}

.theme-card.card .card-body {
    overflow: auto;
}


/* Edit Property Modal Start */

.modal-dialog.edit-popup {
    position: fixed;
    width: 65%;
    max-width: 100%;
    height: 100%;
    top: 0;
    right: -110%;
    margin: 0;
    transition: 0.3s;
}

.modal.show .modal-dialog.edit-popup {
    right: 0;
}

.modal.fade .modal-dialog.edit-popup {
    transform: none !important;
}

.edit-popup .modal-content {
    border: none;
    border-radius: 0;
    /* border-top-left-radius: var(--rounded-big);
    border-bottom-left-radius: var(--rounded-big); */
    max-height: 100%;
    height: 100%;
}

.edit-popup .close-popup.modal-header {
    position: absolute;
    top: 4rem;
    left: -50px;
    background: var(--white);
    width: 50px;
    height: 50px;
    border-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: var(--gray-900);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.edit-popup .close-popup.modal-header .modal-title {
    display: none;
}

.edit-popup .close-popup.modal-header .close {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

.edit-popup .modal-content .modal-body {
    height: 100vh;
    overflow: auto;
}

.edit-popup .badge {
    padding: 0.5rem 0.8rem;
    border-radius: var(--rounded-small);
    margin-right: 0.5rem;
}

.badge-green {
    background: #00b69b20;
    color: var(--theme-green);
}

.badge-blue {
    background: #003ab620;
    color: var(--theme-blue);
}

.badge-red {
    background: #fd545420;
    color: var(--theme-red);
}

.edit-popup h3 {
    color: var(--gray-700);
    font-weight: 700;
}

.edit-popup h4 {
    color: var(--gray-700);
    font-weight: 700;
}

.edit-popup h5 {
    color: var(--gray-700);
    font-weight: 700;
}

.client-show h3 {
    color: var(--gray-700);
    font-weight: 700;
}

.client-show h4 {
    color: var(--gray-700);
    font-weight: 700;
}

.client-show h5 {
    color: var(--gray-700);
    font-weight: 700;
}

.edit-popup p:not(.sun-editor * p) {
    color: var(--gray-500);
    display: flex;
    align-items: center;
}

.client-show li {
    color: var(--gray-500);
    display: flex;
    align-items: center;
}

.client-show p {
    color: var(--gray-500);
    display: flex;
    align-items: center;
		margin-top: 8px !important;
}

.client-show p svg {
    color: var(--gray-700);
    width: 20px !important;
    height: 20px;
    margin-right: 10px;
}

.edit-popup p svg {
    color: var(--gray-700);
    width: 20px !important;
    height: 20px;
    margin-right: 10px;
}

.edit-popup p a {
    color: var(--gray-500);
    text-decoration: underline;
}

.edit-popup .btn-link svg {
    color: inherit;
}

.table-tabs ul.nav-tabs {
    position: relative;
    border: none;
}

.table-tabs .nav-tabs .nav-link {
    margin: 0;
    border: 0;
    border-radius: 0;
    background: var(--silver);
    color: var(--gray-700);
    font-size: 14px;
    padding: 0.8rem 1.2rem;
    cursor: pointer;
}

.table-tabs.lg .nav-tabs .nav-link {
    padding: 1rem 2.5rem;
}

.table-tabs .nav-tabs .nav-link.active {
    background: var(--gray-700);
    color: var(--white);
}

.table-tabs .nav-tabs .nav-link.disabled {
    background: #fafafa;
    color: #bbb;
}

.table-tabs .nav-tabs li.nav-item:first-child {
    border-top-left-radius: var(--rounded-big);
    overflow: hidden;
}

.table-tabs .nav-tabs li.nav-item:last-child {
    border-top-right-radius: var(--rounded-big);
    overflow: hidden;
}

.table-tabs table {
    /* border: none; */
    /* box-shadow: inset 0 0 1px 1px var(--gray-300); */
    border: 1px solid var(--gray-300);
    border-top-left-radius: 0;
    border-top: 0;
}

.table-tabs-button {
    text-align: right;
    position: relative;
    margin-top: -52px;
    margin-bottom: 5px;
}

.cols-dropdown.btn-group {
    position: absolute;
    top: -50px;
    right: 30px;
}

.default-cols {
    position: absolute;
    top: -50px;
    right: 190px;
}


/* Edit Property Modal Ends */


/* Sidebar Right */

.sidebar-right {
    height: calc(100% - 65px);
    background: var(--white);
    position: fixed;
    bottom: 0;
    transition: 0.5s;
    z-index: 999;
    border: 0;
    /* box-shadow: -5px 0 5px 0 rgba(0, 0, 0, 0.12); */
    /* padding: 1rem; */
    overflow-y: auto;
    /* opacity: 0; */
}

.sidebar-right.sm {
    width: 80%;
    right: -80%;
    /* width: 300px;
    right: -300px; */
}

.sidebar-right.md {
    width: 80%;
    right: -80%;
}

.sidebar-right.lg {
    width: 80%;
    right: -80%;
    /* width: 1000px;
    right: -1000px; */
}

.sidebar-right.open {
    right: 0 !important;
    /* opacity: 1; */
}

.btn.sidebar-right-button,
.btn.sidebar-right-button:hover,
.btn.sidebar-right-button:focus {
    position: fixed;
    right: 0;
    top: 5.2rem;
    padding: 0.8rem 1.1rem !important;
    border-radius: 10px 0 0 10px !important;
    background-color: var(--theme-green);
    /* box-shadow: -5px 0 5px 0 rgba(0, 0, 0, 0.12); */
    font-size: 16px;
    width: 54px;
    height: 52px;
    z-index: 1000;
    border: 0;
    transition: 0.5s;
}

.btn.sidebar-right-button:focus {
    box-shadow: none;
    /* box-shadow: -5px 0 5px 0 rgba(0, 0, 0, 0.12); */
}

.btn.sidebar-right-button.hidden {
    right: -54px !important;
    /* display: none; */
    background-color: var(--white);
    transition: 0.5s;
}

.btn.sidebar-right-button.right-positioned {
    right: 80%;
    background-color: var(--white);
    color: var(--gray-700);
}

.info-panel {
    /* padding: 0.5rem 0.5rem !important; */
    padding: 1rem;
    background: var(--silver);
    border: 1px solid var(--gray-300);
    border-radius: var(--rounded-small);
    font-size: 0.82rem;
}


/* Sidebar Right Ends */


/* Full page navigation */

.full-nav-header {
    z-index: 1500 !important;
    background: var(--gray-900);
}

.fullpage-navigation {
    height: 100%;
    width: 100%;
    background: var(--gray-900);
    position: fixed;
    top: -100%;
    transition: 0.3s;
    z-index: 1499;
    border: 0;
    padding: 0 1rem;
    color: var(--white);
    display: none;
}

.fullpage-navigation.open {
    top: 0;
}

.btn.full-nav-button,
.btn.full-nav-button:hover,
.btn.full-nav-button:focus {
    border-radius: 10px !important;
    background-color: var(--theme-green);
    font-size: 16px;
    width: 45px;
    height: 45px;
    border: 0;
    transition: 0.3s;
    text-align: center;
}

.btn.full-nav-button:focus {
    box-shadow: none;
}

.fullpage-navigation .nav-item a {
    font-size: 1.6rem;
    text-decoration: none;
    color: var(--white);
}

.fullpage-navigation .nav-item a:hover,
.fullpage-navigation .nav-item a.active {
    color: var(--theme-green);
}

.v-center {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.user-profile-bottom {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
}

.user-profile-bottom a {
    display: block;
    cursor: pointer;
    color: var(--white);
}

.user-profile-bottom a:hover {
    text-decoration: none;
    color: var(--white);
}

.user-profile-bottom a svg {
    width: 30px !important;
    height: 30px;
    background: white;
    padding: 8px;
    border-radius: 50%;
}

.thumb {
    width: 100px;
    max-width: 100px !important;
    max-height: 100px !important;
    /* height: 100px; */
}

.flex-grow {
    flex: 1;
}


/* Full page navigation Ends */


/* Custom Checkbox */

.custom-checkbox {
    position: relative;
    width: 15px;
    height: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transform: translateY(3px);
}

.custom-checkbox::after {
    position: absolute;
    content: '';
    width: 17px;
    height: 17px;
    background-color: var(--white);
    border-radius: var(--rounded-small);
    border: 1px solid var(--gray-300);
    top: -1px;
    left: -1px;
}

input.custom-checkbox:checked::after {
    border-color: var(--theme-blue);
}

input.custom-checkbox:checked::before {
    content: '';
    position: absolute;
    left: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid var(--theme-blue);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    z-index: 1;
}


/* Custom Checkbox Ends */


/* Applied Filters */

::-webkit-scrollbar{width:2px;height:2px;}
::-webkit-scrollbar-button{width:2px;height:2px;}

.filter-bar-container {
    border-top: 1px solid var(--gray-500);
    background: var(--gray-900);
    /* background: linear-gradient(120deg, var(--gray-700), var(--gray-500)); */
    padding: 0px 15px;
}

.filter-bar {
    background: white;
    border-radius: 50px;
    position: relative;
    display: inline-flex;
    justify-content: space-between;
    min-height: 66px;
    max-width: 1400px;

    /* max-width: 1680px; */
    /* width: 1213px; */
    /* transition: width 1.5s linear; */
    /* transition: background .3s linear; */
    /* transition: .5s linear; */

    width: 100%;
    border: 1px solid #ddd;
    border-radius: 40px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 5%);
}

.filter-bar.active {
    background: #fafafa;
    /* max-width: 9999px; */
    /* transition: .5s linear; */
}

.applied-filters .btn.btn-secondary {
    border-radius: var(--rounded-big) !important;
    background: var(--white);
    border-color: var(--gray-300);
    color: var(--gray-900);
    font-size: 14px;
    padding: 0;
    padding-right: 10px;
    overflow: hidden;
}

.applied-filters .btn.btn-secondary svg {
    color: var(--gray-300);
    transition: 0.2s;
}

.applied-filters .bg-pill {
    font-weight: 500;
    display: block;
    border-radius: 80px;
    border: none;
    background: none;
    /* min-width: 100px; */
    /* width: auto; */
    /* max-width: auto; */
    color: var(--gray-900);
    font-size: 16px;
    height: 100%;
    position: relative;
    display: flex;
    flex: 1 0 0%;
    transition: 0.2s;
    overflow: visible;
    cursor: default;
}

.applied-filters .bg-pill:hover {
    background-color: #eee;
}

.applied-filters .bg-pill.active,
.applied-filters .bg-pill:focus {
    color: var(--gray-900);
    background: #eee;
    background: #fff;
    /* border-color: var(--gray-700); */
    box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 20px !important;
}

.applied-filters .bg-pill.active .bg-pill-header.clear,
.applied-filters .bg-pill.focus .bg-pill-header.clear {
    padding-right: 54px;
}


/*
.applied-filters .bg-pill .bg-pill-header::after {
  background-clip: padding-box !important;
  border: 1px solid transparent !important;
  border-radius: 32px !important;
  bottom: 0px !important;
  content: "" !important;
  left: 0px !important;
  position: absolute !important;
  right: 0px !important;
  top: 0px !important;
  z-index: 0 !important;
}

.applied-filters .bg-pill.active .bg-pill-header::before,
.applied-filters .bg-pill:focus .bg-pill-header::before {
  border-width: 0px 1px !important;
  border-style: solid !important;
  border-color: var(--big-search-form-background, transparent) !important;
  content: "" !important;
  height: 32px !important;
  left: 0px !important;
  margin-top: -16px !important;
  position: absolute !important;
  right: 0px !important;
  top: 50% !important;
  z-index: 0 !important;
  display: block !important;
}

.applied-filters .bg-pill.active .bg-pill-header::after,
.applied-filters .bg-pill:focus .bg-pill-header::after {
  background-clip: padding-box !important;
  border: 1px solid rgb(255, 255, 255) !important;
  border-radius: 32px !important;
  inset: 0px !important;
  content: "" !important;
  position: absolute !important;
  z-index: 0 !important;
  background-color: rgb(255, 255, 255) !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 20px !important;
} */

.applied-filters .bg-pill .bg-pill-header {
    min-width: 140px;
    width: 100%;
    padding: 3px 25px;
    cursor: pointer;
    transition: 0.2s;
}

.applied-filters .bg-pill .clear-filter-button {
    cursor: pointer;
    background-color: #eee;
    color: var(--gray-700);
    width: 26px;
    height: 26px;
    padding: 6px;
    border-radius: 50%;
    position: absolute !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    /* right: 24px !important; */
    right: 16px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.2s linear 0.1s;
}

.applied-filters .bg-pill .clear-filter-button:hover {
    background-color: #ddd;
    /* box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 4px !important; */
}

.applied-filters .bg-pill .filter-value {
    /* background-color: #ddd; */
    max-width: 140px;
    /* box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 4px !important; */
}

.applied-filters .filter-box-container {
    left: 0px !important;
    position: absolute !important;
    right: 0px !important;
    top: 100% !important;
    z-index: 4 !important;
    visibility: visible !important;
    opacity: 1 !important;
    transition: 0.2s;
}

.applied-filters .hidden,
.hidden {
    visibility: hidden !important;
    opacity: 0 !important;
}


/* padding: 30px;
    background: #fff;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    color: black;
    visibility: visible;
    opacity: 1;
    z-index: 4;
} */

.applied-filters .filter-box {
    position: absolute !important;
    left: 0px !important;
    top: 100% !important;
    z-index: 1 !important;
    background: #ffffff !important;
    border-radius: var(--rounded-big) !important;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2) !important;
    margin-top: 8px !important;
    max-height: -webkit-calc(100vh - 150px) !important;
    max-height: -moz-calc(100vh - 150px) !important;
    max-height: calc(100vh - 150px) !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    padding: 24px 32px !important;
    min-width: 490px !important;
    max-width: 490px !important;
}

/* .applied-filters .bg-pill:hover::after {
    background-clip: padding-box !important;
    border: 1px solid rgb(255, 255, 255) !important;
    border-radius: 32px !important;
    content: "" !important;
    position: absolute !important;
    z-index: 0 !important;
    background-color: rgb(255, 255, 255) !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 20px !important;
} */

.applied-filters .btn.btn-secondary:hover>svg {
    color: var(--gray-700) !important;
}

.applied-filters .clear-filter.btn.btn-secondary {
    color: var(--theme-red) !important;
    border: 1px solid var(--theme-red) !important;
    border-radius: var(--rounded-big);
    font-size: 14px !important;
    padding: 3px 10px;
    transition: 0.3s;
}

.applied-filters .clear-filter.btn.btn-secondary svg {
    color: var(--theme-red) !important;
    transition: 0.3s;
}

.applied-filters .clear-filter.btn.btn-secondary:hover {
    background: var(--theme-red);
    color: var(--white) !important;
}

.applied-filters .clear-filter.btn.btn-secondary:hover>svg {
    color: var(--white) !important;
}


/* Applied Filters Ends */


/* Filterbar calendar */

.filter-bar-calendar {
    font-family: unset;
    line-height: 24px;
    /* border: none; */
}


/* .react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 2.1rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
  padding: 0.2rem;
} */

.filter-bar-calendar .react-datepicker__header {
    padding: 8px;
}

.filter-bar-calendar .react-datepicker__day,
.filter-bar-calendar .react-datepicker__day-name {
    width: 2.5rem;
    line-height: 2.4rem;
}

.filter-bar-calendar .react-datepicker__navigation {
    top: 13px;
}

.filter-bar-calendar .react-datepicker__year-wrapper {
    /* max-width: 250px; */
    display: block;
    /* width: 100%; */
}

.filter-bar-calendar .react-datepicker__triangle {
    border-bottom-color: white !important;
}

.filter-bar-calendar .react-datepicker__header {
    background: none;
}

.filter-bar-calendar .react-datepicker__navigation--previous {
    border-right-color: var(--gray-700);
}

.filter-bar-calendar .react-datepicker__navigation--next {
    border-left-color: var(--gray-700);
}


/* .filter-bar-calendar>.react-datepicker__month-container {
  margin-right: 10px;
}
*/


/* .filter-bar-calendar>.react-datepicker__month-container~.react-datepicker__month-container {
  margin-right: unset;
}
*/

.filter-bar-calendar .react-datepicker__year-text--selected,
.filter-bar-calendar .react-datepicker__day--selected,
.filter-bar-calendar .react-datepicker__day--in-range {
    background: var(--gray-700) !important;
    border-radius: 50px;
    /* color: var(--black); */
}

.filter-bar-calendar .react-datepicker__day--in-selecting-range {
    background: var(--gray-500);
    border-radius: 50px;
}

.filter-bar-calendar .react-datepicker__day--range-start,
.filter-bar-calendar .react-datepicker__day--range-end {
    background-color: var(--gray-900) !important;
    color: #fff;
    border-radius: 50px;
}

.filter-bar-calendar .react-datepicker__year-text:hover,
.filter-bar-calendar .react-datepicker__day:hover {
    border-radius: 50px;
}


/* .react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 50%;
  background-color: var(--gray-700) !important;
  color: #fff;
} */


/* Move-in Date calendar Ends */


/* Dashboard Widgets */

.dashboard-widget {
    background: var(--white);
    border: 1px solid var(--gray-300);
    padding: 1.5rem 2rem;
    border-radius: var(--rounded-medium);
    transition: 0.3s;
    margin: 1rem 0;
    color: var(--gray-500);
    display: inline-block;
    width: 100%;
}

.dashboard-widget:hover {
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.06);
    text-decoration: none;
}

.dashboard-widget h2 {
    font-size: 2.2rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.dashboard-widget h6 {
    margin-bottom: 0;
}

.dashboard-widget svg {
    font-size: 2.8rem;
}

.dashboard-widget.red {
    background: var(--theme-red);
    border-color: var(--theme-red);
    color: var(--white);
}

.dashboard-widget.green {
    background: var(--theme-green);
    border-color: var(--theme-green);
    color: var(--white);
}

.dashboard-widget.blue {
    background: var(--theme-blue);
    border-color: var(--theme-blue);
    color: var(--white);
}

.dashboard-widget.yellow {
    background: var(--theme-yellow);
    border-color: var(--theme-yellow);
    color: var(--white);
}

.dashboard-widget.purple {
    background: #5e72e4;
    border-color: #5e72e4;
    color: var(--white);
}

.dashboard-widget.orange {
    background: #fb6340;
    border-color: #fb6340;
    color: var(--white);
}

.dashboard-widget.skyblue {
    background: #11cdef;
    border-color: #11cdef;
    color: var(--white);
}

.dashboard-widget.pink {
    background: #f3a4b5;
    border-color: #f3a4b5;
    color: var(--white);
}


/* Dashboard Widgets Ends */


/* Theme Tabing panel */

.theme-tabs .nav-item a.nav-link {
    cursor: pointer;
    border-top-left-radius: var(--rounded-small);
    border-top-right-radius: var(--rounded-small);
    color: var(--gray-500);
}

.theme-tabs .nav-item a.nav-link.active {
    color: var(--gray-800);
    border-color: var(--gray-300);
    border-bottom-color: var(--white);
}

.theme-tabs.nav-tabs {
    border-color: var(--gray-300);
}


/* Theme Tabing panel Ends */


/* Input box with icons */

.inputs-icons .input-group-text {
    background: var(--white);
    border-color: var(--gray-300);
    border-top-left-radius: var(--rounded-small) !important;
    border-bottom-left-radius: var(--rounded-small) !important;
    border-right: 0;
}

.inputs-icons .form-control {
    border-color: var(--gray-300);
    border-top-right-radius: var(--rounded-small) !important;
    border-bottom-right-radius: var(--rounded-small) !important;
    /* border-left: 0;
    padding-left: 0; */
}

.inputs-icons-right .input-group-text {
    background: var(--white);
    border-color: var(--gray-300);
    border-radius: 0;
    border-top-right-radius: var(--rounded-small) !important;
    border-bottom-right-radius: var(--rounded-small) !important;
    border-left: 0;
}

.inputs-icons-right .form-control {
    border-color: var(--gray-300);
    border-top-left-radius: var(--rounded-small) !important;
    border-bottom-left-radius: var(--rounded-small) !important;
    border-right: 0;
    padding-right: 0;
}

.inputs-icons .form-control:focus {
    box-shadow: none !important;
    border-color: var(--gray-300);
}

.inputs-icons .react-datepicker-wrapper .form-control {
    /* border-left: 1px solid; */
    padding-left: 0.75rem;
}


/* Input box with icons Ends */


/* React data grid START */

.fullscreen-enabled {
    background: white;
    padding: 12px;
}

.remove-item {
    position: absolute;
    right: 21px;
    top: 5px;
    padding: 3px;
    color: white;
    /* margin: 3px; */
    min-width: 20px;
    min-height: 20px;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    border-radius: 50%;
    cursor: pointer;
}

.remove-item.my-profile {
    right: auto;
    left: 75px;
}

.photo-count {
    position: absolute;
    right: 5px;
    top: 4px;
    padding: 2px 5px;
    color: white;
    margin: 3px;
    min-width: 20px;
    min-height: 20px;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    border-radius: var(--rounded-small);
    /* cursor: pointer; */
    line-height: 1rem;
    font-size: 0.7rem;
}

.remove-mb {
    margin-bottom: 0px !important;
}

.grid-status {
    /* background: #fffac2; */
    /* color: var(--dark-blue); */
    /* border: 1px solid #b9b68d !important; */
    /* border: none; */
    display: inline-block;
    border-radius: 50% !important;
    /* font-size: 1.1rem !important; */
    font-size: 0.5rem;
    /* padding: 1rem; */
    min-width: 15px !important;
    min-height: 15px !important;
    cursor: pointer;
    transition: 0.3s;
    margin-left: 1rem;
    position: relative;
}

.photo-editor-container {
    position: fixed;
    inset: 0;
    display: grid;
    place-items: center;
    /* background: white; */
    /* width: 100%; */
    /* padding: 1rem; */
    background: rgba(0, 0, 0, 0.7);
}

.big-modal {
    width: 90vw !important;
    /* height: 90vh !important; */
    /* min-height: 90vh !important; */
    /* margin: unset auto !important; */
    /* display: flex; */
    /* padding: 1rem; */
    /* align-self: center; */
    max-width: unset !important;
    min-width: auto;
}

.big-modal .modal-title {
    width: 100%;
}

.big-modal .photos {
    max-height: 50vh;
    overflow: scroll;
}

.pending-status {
    background: var(--theme-yellow);
}

.progress-status {
    background: var(--theme-blue);
}

.saved-status {
    background: var(--theme-green);
}

.error-status {
    background: var(--theme-red);
}

.loading-status::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: inherit;
    border-radius: 50%;
    z-index: -1;
    animation: ripple 1.5s ease-out infinite;
}

@keyframes ripple {
    from {
        opacity: 1;
        transform: scale(0);
    }
    to {
        opacity: 0;
        transform: scale(3);
    }
}

.default-select__menu {
    font-size: 0.9rem;
    margin-top: 3px !important;
    overflow: hidden;
}

.default-select__menu-list {
    padding: 0px !important;
}

.default-select__option {
    line-height: 18px;
}

.default-select__option--is-focused {
    background: var(--silver) !important;
    color: inherit !important;
}

.default-select__option--is-selected {
    background: var(--gray-700) !important;
    color: white !important;
}

.default-select__value-container {
    padding: 0px !important;
}

.default-select__placeholder {
    font-size: 0.9rem;
    font-weight: 300;
}

.default-select__multi-value {
    font-size: 0.9rem;
    font-weight: 300;
}

.property-grid .filters input,
.property-grid .filters select {
    color: #495057 !important;
    display: inline-block;
    width: 100%;
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid var(--gray-300);
    border-radius: var(--rounded-small);
    padding: 0.3rem 0.5rem !important;
    background: var(--white);
}

.property-grid input[type='checkbox'] {
    display: none;
}

.grid-multi-select {
    line-height: 20px;
    width: 100%;
    height: 100%;
    position: absolute !important;
}

.grid-multi-select .default-select__control {
    padding: 2px 6px;
    line-height: 20px;
    width: 100%;
    height: 100%;
    border: none !important;
    border-radius: 0px;
    background: none;
    max-height: 34px !important;
    min-height: 34px !important;
}

.filter-multi-select {
    line-height: 20px;
    width: 100%;
    height: 32px;
    overflow: hidden;
    /* font-size: unset; */
    /* font-weight: unset; */
    /* position: absolute !important; */
}

.filter-multi-select .default-select__value-container {
    height: 100%;
    line-height: 15px;
}

.filter-multi-select .default-select__input input {
    padding: 0px !important;
}

.filter-multi-select .default-select__multi-value__remove {
    color: #555;
}


/* .filter-multi-select .default-select__multi-value__remove:hover {
  color: black;
} */

.filter-multi-select .default-select__control {
    padding: 2px 6px;
    /* line-height: 18px; */
    width: 100%;
    height: 100%;
    max-height: 32px !important;
    min-height: 32px !important;
}

.no-border {
    border: none !important;
    border-radius: 0px;
}

.property-grid .unit-info {
    line-height: 20px !important;
    padding: 0px 0px 8px 0px !important;
    overflow: clip;
    text-overflow: ellipsis;
    /* font-size: 0.8rem !important; */
}

.property-grid .edit-unit-info {
    padding-left: 8px !important;
    overflow: clip;
    text-overflow: ellipsis;
    /* font-size: 0.8rem !important; */
}

.property-grid button {
    padding: 2px 10px !important;
    font-size: 0.8rem !important;
}

.property-grid div[role='grid'] {
    background: white;
    color: inherit;
    text-align: left;
    border: 1px solid #dee2e6;
    border-radius: var(--rounded-medium) var(--rounded-medium) 0 0;
    width: '100%';
    /* height: '89vh'; */
    height: -webkit-calc(100vh - 130px);
    height: -moz-calc(100vh - 130px);
    height: calc(100vh - 130px);
}

.property-grid div[role='row'] {
    background: white;
    color: inherit;
}

.property-grid div[aria-selected='true']:not([role='row']) {
    background-color: var(--silver);
    box-shadow: inset 0 0 0px 1px var(--gray-500);
}

.property-grid div[aria-selected='true'][role='row'] {
    /* background: none; */
    /* box-shadow: inset 0 0 25px 1px var(--theme-blue); */
    /* color:white; */
    /* background: var(--theme-blue); */
    background: var(--gray-300);
}

.property-grid div[role='gridcell'] {
    text-align: left;
    vertical-align: top;
    border: none;
    border-bottom: 1px solid #dee2e6;
}

.property-grid div[role='gridcell']>a {
    color: inherit;
}

.property-grid div[role='gridcell']>input,
.property-grid div[role='gridcell']>select {
    color: inherit;
    /* background-color: inherit; */
    /* border: inherit; */
    padding: 0 8px;
    border: none;
    text-align: inherit;
    text-decoration-color: inherit;
    background: transparent;
    border: none;
    outline: none;
    box-shadow: none;
}

.property-grid div[role='gridcell']>select {
    /* width: 95%; */
    width: calc(100% - 10px);
    padding-left: 4px !important;
}

.property-grid div[role='gridcell']>input::selection {
    background-color: rgb(194, 214, 240);
}

.property-grid div[role='gridcell']>input::-moz-selection {
    background-color: rgb(194, 214, 240);
}

.property-grid .blank-filters {
    color: #495057 !important;
    display: inline-block;
    width: 100%;
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid var(--gray-900);
    border-radius: var(--rounded-small);
    padding: 0.3rem 0.5rem !important;
    background: var(--gray-700);
}

.property-grid .filters {
    line-height: 20px;
}

.property-grid .hide-filters {
    display: none;
}

.property-grid .no-outline {
    outline: none !important;
}

.property-grid .new-row {
    background: var(--highlight) !important;
}

.property-grid .rdg-date-picker,
.property-grid .rdg-date-picker:focus {
    appearance: none;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0px !important;
    padding: 0px 8px;
    vertical-align: top;
    color: var(--rdg-color);
    background-color: var(--rdg-background-color);
    font-family: inherit;
    font-size: var(--rdg-font-size);
    outline: none;
}

.property-grid .rdg-date-picker::-webkit-calendar-picker-indicator {
    margin: 0px;
    position: relative;
    /* opacity: 1; */
    /* filter: invert(100%); */
    margin-right: -0.25em;
    filter: invert(48%) sepia(30%) saturate(0%) hue-rotate(203deg) brightness(90%) contrast(95%);
    cursor: pointer;
}

.property-grid .rdg-text-editor {
    appearance: none;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0px 6px 0 6px;
    border: 2px solid #ccc;
    vertical-align: top;
    color: var(--rdg-color);
    background-color: var(--rdg-background-color);
    font-family: inherit;
    font-size: var(--rdg-font-size);
}

.property-grid .rdg-text-editor:focus {
    border-color: var(--rdg-selection-color);
    outline: none;
}

.property-grid .rdg-text-editor:placeholder {
    color: #999;
    opacity: 1;
}

.property-grid .rdg-checkbox-label {
    margin-right: 0px !important;
}

.property-grid .rdg-checkbox {
    position: relative;
    -webkit-user-select: none;
    user-select: none;
    transform: translateY(3px);
    width: 17px;
    height: 17px;
    background-color: var(--white);
    border-radius: var(--rounded-small);
    border: 1px solid var(--gray-300);
}

.property-grid .rdg-checkbox-input:checked+.rdg-checkbox {
    background-color: var(--white);
    box-shadow: none;
    border-color: var(--theme-blue);
}

.property-grid .rdg-checkbox-input:checked+.rdg-checkbox::before {
    content: '';
    position: absolute;
    left: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid var(--theme-blue);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    z-index: 1;
}

.property-grid div[role='columnheader'] {
    color: rgb(255, 255, 255) !important;
    background-color: var(--gray-700) !important;
    border: none;
    border-collapse: collapse;
    border-right: 1px solid var(--gray-300);
    /* transition: 0.3s; */
    line-height: var(--header-row-height);
    height: var(--header-row-height);
    /* !important; */
    transition: 0.2s;
}

.property-grid div[role='columnheader'] select {
    /* width: 95%; */
    padding-left: 4px !important;
}

.property-grid div[role='columnheader'] input::selection {
    background-color: rgb(194, 214, 240);
}

.property-grid div[role='columnheader'] input::-moz-selection {
    background-color: rgb(194, 214, 240);
}

.property-grid .rdg-header-row {
    /* header row styles */
    text-align: center;
}

.property-grid .rdg-summary-row {
    color: rgb(255, 255, 255) !important;
    background-color: var(--gray-700) !important;
    border: none;
    border-collapse: collapse;
}

.property-grid .text-center {
    text-align: center;
    line-height: inherit;
}

.property-grid .text-left {
    text-align: left;
    line-height: inherit;
}

.property-grid .text-right {
    text-align: right;
    line-height: inherit;
}


/* React data grid END */


/* Carousel START */

.slider {
    max-width: 100%;
    width: 100%;
    max-height: 500px;
}


/* Carousel END */


/* Property Reports Grid */

.property-card {
    cursor: pointer;
}

.property-card:hover {
    text-decoration: none;
}

.property-card .card-img {
    border-radius: var(--rounded-medium);
    height: 280px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 0.8rem;
}

/* .slider .carousel-item span,
.slider .carousel-item span img, */
.slider .carousel-item,
.slider .carousel-item img{
    width: 100% !important;
    height: 100%;
		background-color: #F6F9FC;
    min-height: 300px;
    max-height: 500px;
    object-fit: cover;
		border-radius: var(--rounded-medium);
}
.carousel-indicators {
    bottom: 25px !important;
}

/* .slider .carousel-item span,
.property-card .card-img span{
	display: block !important;
} */

.carousel .carousel-control-next-icon, .carousel .carousel-control-prev-icon {
    opacity: 0;
}
.carousel:hover .carousel-control-next-icon, .carousel:hover .carousel-control-prev-icon {
    opacity: 1;
    transition: opacity .5s;
}
.carousel-control-next, .carousel-control-prev {
    opacity: 0.9 !important;
}

.property-card .card-img,
.property-card .card-img img {
    width: 100% !important;
    height: 100%;
		background-color: #F6F9FC;
    min-height: 250px;
    max-height: 250px;
    object-fit: cover;
}

.property-card .like-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 22px;
    z-index: 3;
    padding: 0.2rem 0.7rem;
}

.property-card h4 {
    font-size: 1.2rem;
    color: var(--gray-900);
}

.property-card p {
    margin-bottom: 0;
    color: var(--gray-500);
}

.property-report-map {
    position: sticky;
    /* top: 80px; */
    border-bottom-left-radius: var(--rounded-medium);
    overflow: hidden;
}

.property-report-image {
    position: relative;
    height: 430px;
    overflow: hidden;
    border-radius: var(--rounded-medium);
}

.property-report-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.property-report-image .slide,
.property-report-image .slide .carousel-inner,
.property-report-image .slide .carousel-inner .carousel-item,
.property-card .slide,
.property-card .slide .carousel-inner,
.property-card .slide .carousel-inner .carousel-item {
    height: 100%;
}

.property-report-image-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0.3rem;
    background: rgba(0, 0, 0, 0.7);
    color: var(--white);
    border-bottom-left-radius: var(--rounded-medium);
    border-bottom-right-radius: var(--rounded-medium);
    /* z-index: 99; */
}

.property-report-image-overlay h3,
.property-report-image-overlay h5,
.property-report-image-overlay p {
    color: var(--white);
}

.tour-request-inputs {
    padding: 0.5rem 0.8rem;
    border-radius: var(--rounded-big);
    background: var(--white);
    border: 1px solid var(--gray-300);
    display: inline-block;
    margin-right: 1rem;
}

.tour-request-inputs input,
.tour-request-inputs .react-time-picker__wrapper,
.tour-request-inputs .react-datepicker-wrapper {
    width: 212px !important;
    border: none;
    outline: none;
}

.tour-request-inputs .react-time-picker__clear-button,
.tour-request-inputs .react-time-picker__clock-button {
    display: none;
}

.property-report-price-card.card {
    border-radius: var(--rounded-medium);
    border-color: var(--gray-300);
}

.sticky-bottom {
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 1020;
}

.sticky-bottom .tour-request-inputs {
    border-right: none !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0 !important;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.sticky-bottom .tour-request-button {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    padding: 0.55rem 1rem;
}

.no-image {
    width: 100%;
    height: 280px;
    background: #F6F9FC;
    /* border: 1px solid var(--gray-300); */
    color: var(--gray-700);
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--rounded-medium);
}


/* Property Reports Grid Ends */


/* Add User page */

.panel-tabs ul.nav-tabs {
    position: relative;
    border: none;
    border-radius: var(--rounded-big);
    overflow: hidden;
}

.panel-tabs .nav-tabs .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}

.panel-tabs .nav-tabs .nav-link {
    margin: 0;
    border: 0;
    border-radius: 0;
    background: var(--silver);
    color: var(--gray-700);
    font-size: 1rem;
    padding: 0.8rem;
    cursor: pointer;
    text-align: center;
}

.panel-tabs.lg .nav-tabs .nav-link {
    padding: 1rem 2.5rem;
}

.panel-tabs .nav-tabs .nav-link.active {
    background: var(--gray-700);
    color: var(--white);
}


/* Add User page Ends */


/* Toggle switch code */

.react-switch-checkbox {
    height: 0;
    width: 0 !important;
    visibility: hidden;
}

.react-switch-label {
    display: inline-block;
    cursor: pointer;
    width: 70px;
    height: 32px;
    background: var(--gray-300);
    border-radius: 100px;
    position: relative;
    transition: background-color 0.2s;
    margin-bottom: 0;
    /* margin-top: -43px; */
}

.react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 28px;
    height: 28px;
    border-radius: 45px;
    transition: 0.2s;
    background: var(--white);
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked+.react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.toggle-container {
    /* width: 28%; */
    background-color: var(--silver);
    border: 1px solid var(--gray-300);
    cursor: pointer;
    user-select: none;
    border-radius: 8px;
    padding: 4px;
    position: relative;
    display: inline-flex;
}

.simple-toggle-container {
    /* width: 28%; */
    background-color:  var(--theme-blue);
    border: 1px solid var(--gray-300);
    cursor: pointer;
    user-select: none;
    border-radius: 8px;
    padding: 4px;
    position: relative;
    display: inline-flex;
}

.simple-toggle-container-disabled {
    /* width: 28%; */
    background-color: grey;
    border: 1px solid var(--gray-300);
    cursor: pointer;
    user-select: none;
    border-radius: 8px;
    padding: 4px;
    position: relative;
    display: inline-flex;
}

.toggle-button {
    font-size: 14px;
    line-height: 10px;
    font-weight: bold;
    cursor: pointer;
    background-color: var(--gray-700);
    color: white;
    padding: 8px 12px;
    border-radius: 6px;
    box-sizing: border-box;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease;
    display: inline-block;
    position: relative;
    left: calc(100% - 1px);
    transform: translateX(-100%);
}

.toggle-disabled {
    background-color: var(--gray-500);
    transform: translateX(0%);
    /* left: calc(100% + 2px); */
    left: 1px;
}

.simple-toggle-button {
    font-size: 14px;
    line-height: 10px;
    font-weight: bold;
    cursor: pointer;
    background-color: white;
    color: white;
    padding: 8px 12px;
    border-radius: 6px;
    box-sizing: border-box;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease;
    display: inline-block;
    position: relative;
    left: calc(100% - 1px);
    transform: translateX(-100%);
}

.simple-toggle-disabled {
    background-color: white;
    transform: translateX(0%);
    /* left: calc(100% + 2px); */
    left: 1px;
}

.properties-toggle-switch .toggle-container {
    background-color: var(--white);
}

.properties-toggle-switch .toggle-button {
    background-color: var(--theme-blue);
    font-size: 16px;
    padding: 12px;
    font-weight: normal;
}

.properties-toggle-switch .toggle-disabled {
    background-color: var(--theme-green);
}


/* Toggle switch code Ends */


/* For Tripple Toggle Switch */

.tripple-toggle-container input[type="radio"] {
  display: none;
}

.tripple-toggle-container label {
  /* color: white; */
  z-index: 10;
  cursor: pointer;
}

.tripple-toggle-container p {
  margin: 0;
  font-weight: 100;
	font-size: 12px;
}

.tripple-toggle-container .black-font p{
  color: white !important;
	font-weight: bold;
	font-size: 10px;
}

.tripple-toggle-container {
  display: inline-block;
  vertical-align: middle;
  width: 160px;
  height: 35px;
  border-radius: 100px;
  background-color: var(--silver);
  border: 1px solid var(--gray-300);
	position: relative;
	display: inline-flex;
	box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.switch {
  height: 27px;
  width: 45px;
	background-color: var(--gray-700);
	color: white;
  border-radius: 100px;
  position: absolute;
  left: 3px;
  top: 3px;
  transition: ease-in;
	box-sizing: border-box;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

/*---------------- LEFT ------------------- */
.switch.left-to-center {
  animation-name: leftToCenter;
  animation-duration: 0.5s;
}

.switch.left-to-right {
  animation-name: leftToRight;
  animation-duration: 0.5s;
}

.switch.left-position {
  left: 3px;
}

.left-label {
  position: absolute;
}

#left + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 50px;
  border-radius: 100px;
}

#left:checked + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 50px;
  border-radius: 100px;
}

@keyframes leftToCenter {
  from {
    left: 3px;
  }
  to {
    left: 53px;
  }
}

@keyframes leftToRight {
  from {
    left: 3px;
  }
  to {
    left: 106px;
  }
}

/* -------------------- center ------------------ */
.switch.center-to-left {
  animation-name: centerToLeft;
  animation-duration: 0.5s;
}

.switch.center-to-right {
  animation-name: centerToRight;
  animation-duration: 0.5s;
}

.switch.center-position {
  left: 50px;
}

.center-label {
  position: absolute;
  left: 50px;
}

#center + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 50px;
  border-radius: 100px;
}

#center:checked + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 50px;
  border-radius: 100px;
}

@keyframes centerToLeft {
  from {
    left: 53px;
  }
  to {
    left: 3px;
  }
}

@keyframes centerToRight {
  from {
    left: 53px;
  }
  to {
    right: 3px;
  }
}

/* ------------------- RIGHT ------------------- */
.switch.right-to-left {
  animation-name: rightToLeft;
  animation-duration: 0.5s;
}

.switch.right-to-center {
  animation-name: rightToCenter;
  animation-duration: 0.5s;
}

.switch.right-position {
  left: 106px;
}

.right-label {
  position: absolute;
  right: 2px;
}

#right + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 50px;
  border-radius: 100px;
}

#right:checked + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 50px;
  border-radius: 100px;
}

@keyframes rightToLeft {
  from {
    left: 106px;
  }
  to {
    left: 3px;
  }
}

@keyframes rightToCenter {
  from {
    left: 106px;
  }
  to {
    right: 53px;
  }
}


/* End Tripple Toggle */


/* New Pricing plan Cards */

.pricing-card.card {
    border: none;
    border-radius: var(--rounded-medium);
    overflow: hidden;
}

.pricing-card .card-body {
    padding: 0;
    text-align: center;
}

.pricing-card .card-title {
    padding: 1rem 0;
    display: inline-block;
    width: 100%;
    margin: 0;
    font-weight: bold;
    font-size: 1.5rem;
}

.pricing-card .card-title.true {
    padding: 1.4rem 0;
}

.pricing-card .card-subtitle {
    padding: 1.5rem 0;
    display: inline-block;
    width: 100%;
    margin: 0;
    font-size: 2.2rem;
    background-color: var(--gray-800);
    color: var(--white);
    font-weight: bold;
}

.pricing-card .card-subtitle small {
    font-weight: normal;
    font-size: 1.2rem;
}

.pricing-card .card-subtitle p {
    font-size: 0.8rem;
    font-weight: normal;
    margin-top: 0.5rem;
}

.pricing-card .card-text {
    padding: 1rem 0;
    display: inline-block;
    width: 100%;
    margin: 0;
    background-color: var(--gray-900);
    color: var(--white);
    font-size: 1.2rem;
    border-bottom: 1px solid var(--gray-800);
}

.pricing-card .card-text:last-child {
    border-bottom: 0;
}

.pricing-card .btn {
    width: 100%;
    margin-top: 1rem;
    border-radius: 0;
    font-size: 1.2rem;
}

.pricing-card .btn.btn-red,
.pricing-card.red .card-title {
    background-color: var(--theme-red) !important;
    color: var(--white) !important;
}

.pricing-card .btn.btn-blue,
.pricing-card.blue .card-title {
    background-color: var(--theme-blue) !important;
    color: var(--white) !important;
}

.pricing-card .btn.btn-yellow,
.pricing-card.yellow .card-title {
    background-color: var(--theme-yellow) !important;
    color: var(--white) !important;
}

.pricing-card .btn.btn-green,
.pricing-card.green .card-title {
    background-color: var(--theme-green) !important;
    color: var(--white) !important;
}

.btn-red:hover,
.btn-yellow:hover {
    box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.12);
}


/* New Pricing plan Cards Ends */


/* noUI Slider Starts */


/*! nouislider - 14.7.0 - 4/6/2021 */


/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */

.noUi-target,
.noUi-target * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.noUi-target {
    position: relative;
}

.noUi-base,
.noUi-connects {
    width: 100%;
    height: 100%;
    position: relative;
    /* z-index: 1; */
}


/* Wrapper for all connect elements.
  */

.noUi-connects {
    overflow: hidden;
    /* z-index: 0; */
}

.noUi-connect,
.noUi-origin {
    will-change: transform;
    position: absolute;
    /* z-index: 1; */
    top: 0;
    right: 0;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -webkit-transform-style: preserve-3d;
    transform-origin: 0 0;
    transform-style: flat;
}

.noUi-connect {
    height: 100%;
    width: 100%;
}

.noUi-origin {
    height: 10%;
    width: 10%;
    z-index: 0 !important;
}


/* Offset direction
  */

.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
    left: 0;
    right: auto;
}


/* Give origins 0 height/width so they don't interfere with clicking the
  * connect elements.
  */

.noUi-vertical .noUi-origin {
    width: 0;
}

.noUi-horizontal .noUi-origin {
    height: 0;
}

.noUi-handle {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    outline: none;
}

.noUi-touch-area {
    height: 100%;
    width: 100%;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
    -webkit-transition: transform 0.3s;
    transition: transform 0.3s;
}

.noUi-state-drag * {
    cursor: inherit !important;
}


/* Slider size and handle placement;
  */

.noUi-horizontal {
    height: 9px;
}

.noUi-horizontal .noUi-handle {
    width: 28px;
    height: 28px;
    right: -14px;
    top: -11px;
}

.noUi-vertical {
    width: 18px;
}

.noUi-vertical .noUi-handle {
    width: 28px;
    height: 28px;
    right: -6px;
    top: -17px;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
    left: -17px;
    right: auto;
}


/* Styling;
  * Giving the connect element a border radius causes issues with using transform: scale
  */

.noUi-target {
    background: #fafafa;
    border-radius: var(--rounded-medium);
    border: 1px solid var(--gray-300);
    /* #D3D3D3; */
    box-shadow: inset 0 1px 1px #f0f0f0, 0 3px 6px -5px #bbb;
}

.noUi-connects {
    border-radius: 3px;
}

.noUi-connect {
    background: var(--gray-700);
}


/* Handles and cursors;
  */

.noUi-draggable {
    cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
    cursor: ns-resize;
}

.noUi-handle {
    border: 1px solid var(--gray-300);
    /* #D9D9D9; */
    /* border-radius: 3px; */
    border-radius: 50%;
    background: #fff;
    /* background: var(--gray-300); */
    cursor: pointer;
    box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
}

.noUi-active {
    box-shadow: inset 0 0 1px #fff, inset 0 1px 7px var(--gray-300), 0 3px 6px -3px #bbb;
}


/* Handle stripes;
  */

.noUi-handle:before,
.noUi-handle:after {
    content: '';
    display: none;
    position: absolute;
    height: 14px;
    width: 1px;
    background: #e8e7e6;
    left: 14px;
    top: 6px;
}

.noUi-handle:after {
    left: 17px;
}

.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
    width: 14px;
    height: 1px;
    left: 6px;
    top: 14px;
}

.noUi-vertical .noUi-handle:after {
    top: 17px;
}


/* Disabled state;
  */

[disabled] .noUi-connect {
    background: #b8b8b8;
}

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
    cursor: not-allowed;
}


/* Base;
  *
  */

.noUi-pips,
.noUi-pips * {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.noUi-pips {
    position: absolute;
    color: var(--gray-700);
    z-index: -1;
}


/* Values;
  *
  */

.noUi-value {
    position: absolute;
    white-space: nowrap;
    text-align: center;
    margin-top: 8px;
    font-size: 10px;
    /* display: none; */
}

.noUi-value-sub {
    color: #ccc;
    font-size: 10px;
}


/* Markings;
  *
  */

.noUi-marker {
    position: absolute;
    background: var(--gray-300);
}

.noUi-marker-sub {
    background: var(--gray-300);
}

.noUi-marker-large {
    background: var(--gray-300);
}


/* Horizontal layout;
  *
  */

.noUi-pips-horizontal {
    padding: 10px 0;
    height: 80px;
    top: 100%;
    left: 0;
    width: 100%;
}

.noUi-value-horizontal {
    -webkit-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
    -webkit-transform: translate(50%, 50%);
    transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
    margin-left: -1px;
    width: 2px;
    height: 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
    height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
    height: 15px;
}


/* Vertical layout;
  *
  */

.noUi-pips-vertical {
    padding: 0 10px;
    height: 100%;
    top: 0;
    left: 100%;
}

.noUi-value-vertical {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding-left: 25px;
}

.noUi-rtl .noUi-value-vertical {
    -webkit-transform: translate(0, 50%);
    transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
    width: 5px;
    height: 2px;
    margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
    width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
    width: 15px;
}

.noUi-tooltip {
    display: block;
    font-size: 0.7rem;
    font-weight: 700;
    position: absolute;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    background: #fff;
    color: #000;
    padding: 4px 8px 3px;
    text-align: center;
    white-space: nowrap;
}


/* .noUi-tooltip::after {
    content: " sqft";
} */

.noUi-horizontal .noUi-tooltip {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 110%;
}

.noUi-vertical .noUi-tooltip {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    top: 50%;
    right: 120%;
}

.noUi-horizontal .noUi-origin>.noUi-tooltip {
    -webkit-transform: translate(50%, 0);
    transform: translate(50%, 0);
    left: auto;
    bottom: 10px;
}

.noUi-vertical .noUi-origin>.noUi-tooltip {
    -webkit-transform: translate(0, -18px);
    transform: translate(0, -18px);
    top: auto;
    right: 28px;
}

.noUi-tooltip {
    display: none;
}

.noUi-active .noUi-tooltip {
    display: block;
}


/* noUI Slider Ends */

.w-35 {
    width: 35% !important;
}


/* google map property info popup */

.gm-style-iw {
    top: -10px !important;
}


/* google map property info popup end*/

.map-price-marker {
    position: absolute;
    font: 400 11px Roboto, Arial, sans-serif;
    width: 90px;
    height: 28px;
    border-radius: 3px;
    background-color: white;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.07);
    text-align: center;
    color: black;
    font-size: 14px;
    font-weight: 900;
    padding: 0px 0px;
    border-radius: 28px;
    line-height: 15px;
    box-shadow: rgb(0 0 0 / 4%) 0px 0px 0px 1px, rgb(0 0 0 / 18%) 0px 2px 4px;
}

.map-price-marker:after {
    left: 31px;
    top: 19px;
    border-width: 5px;
    border-color: white transparent transparent;
}

.map-price-marker:before {
    left: 30px;
    top: 20px;
    border-width: 6px;
    border-color: gray transparent transparent;
}

.map-price-marker.visited:hover {
    background-color: #7b8d93;
    color: #fff;
    border-color: #5b6a6f;
}

.map-price-marker.visited:hover:after {
    border-color: #7b8d93 transparent transparent;
}

.map-price-marker.visited:hover:before {
    border-color: #5b6a6f transparent transparent;
}

.map-price-marker:hover {
    z-index: 3;
    transition: 0.2s;
    -ms-transform: scale(1.1);
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari 3-8 */
    transform: scale(1.1);
}

.map-price-marker:hover:after {
    border-color: #f2f2f2 transparent transparent;
}

.map-price-marker-active,
.map-price-marker-active:hover {
    color: white;
    background-color: #000;
    z-index: 2;
}

.map-price-marker:hover:before {
    border-color: gray transparent transparent;
}

.balloon {
    overflow: hidden;
    background: #ffffff;
    width: 274px;
    box-shadow: 0 8px 28px rgba(0, 0, 0, 0.28);
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 1000;
    pointer-events: auto;
    transform: translate(-50%);
    border-radius: var(--rounded-medium);
}

.circleText {
    text-align: center;
    height: 50%;
    left: 50%;
    position: absolute;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
}


/* in slider width for map only */

.sliderWidth {
    width: 274px;
}

.card-img.sliderWidth {
    width: 274px;
    height: 154px;
    overflow: hidden;
}

.card-img.sliderWidth .carousel.slide,
.card-img.sliderWidth .no-image {
    max-height: 100%;
    border: none;
}

.card-img.sliderWidth img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.infobox-text {
    font-size: 0.9rem;
}

.infobox-title {
    font-size: 0.9rem;
    font-weight: bold;
}

.visitedIcon {
    background-color: #ebebeb;
}

.plan-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 70px;
    max-width: 70px;
    cursor: pointer;
}

.zoom-image {
    /* padding: 50px;
  background-color: green; */
    transition: transform 0.2s;
    /* width: 200px;
  height: 200px; */
    margin: 0 auto;
}

.zoom-image:hover {
    -ms-transform: scale(1.5);
    /* IE 9 */
    -webkit-transform: scale(1.5);
    /* Safari 3-8 */
    transform: scale(1.5);
    overflow: visible !important;
}

.client-rating-notes-icon {
    position: absolute;
    right: 2rem;
    top: 1.5rem;
    z-index: 500;
    background: var(--white);
    /* border: 1px solid var(--gray-300); */
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 6px 10px rgb(0 0 0 / 12%);
}

.client-rating-notes-icon svg {
    cursor: pointer;
}

.theme-popover .popover {
    border-radius: var(--rounded-medium);
    box-shadow: 5px 0 5px 0 rgba(0, 0, 0, 0.12);
}

.theme-popover .popover .popover-header {
    border-top-left-radius: var(--rounded-medium);
    border-top-right-radius: var(--rounded-medium);
}

.card-body .client-rating-notes-icon {
    right: 0.96rem;
    top: 0.5rem;
}

.more-prices .card-body>.client-rating-notes-icon {
    right: 0.8rem;
    top: 0.5rem;
}

.popover.bs-popover-auto {
    z-index: 9999;
}

.spacer-div{
	height: 200px;
}

.rtable-bottom-space{
	height: 90px;
}

.floor-plan-img{
	width: 50px;
	height: 50px;
}

.floorplan-photo-editor{
	width: 100%;
	height: 500px;
	object-fit: contain;
}

.fees-sheet-box{
	padding: 35px 10px;
	background-color: #e7e7e7;
	height: 100px;
	border-radius: 7px;
	text-decoration: none;
}

.invoice-divider{
	margin: 10px;
	height: 35px;
	background-color: #EFF4FC;
}

.invoice-show-icon:hover{
	cursor: pointer;
}

.invoice-show-icon-menu{
	justify-content: right;
	display: flex;
	align-items: center;
}

.invoice-show-popup{
	overflow-x: hidden;
}

.unit-box{
	border-radius: 0 !important;
	border-top: 1px solid rgba(0,0,0,.1) !important;
	border-right: 0px !important;
	border-left: 0px !important;
	border-bottom: 0px !important;

}

.unit-box:hover{
	background-color: #f6f9fc;
	transition: 0.5s;
}

.unit-box .card-body{
	padding: 1rem !important;
}


.invite-agent-modal {
	color: var(--gray-800);
}

.invite-agent-modal a{
	color: var(--blue);
}

.inline-block{
	display: inline-block;
	vertical-align: middle;
}

.upcoming-invoice-badge{
	background-color: rgb(94,114,228) !important;
	color: #ffffff !important;
	padding: 10px !important;
}

.otp-input-field {
	font-size: 20px;
	letter-spacing: 20px;
	border: none;
  outline: none;
  border-bottom: 2px solid #ccc;
}

.inquiry-link-box {
	border: 2px dashed var(--blue);
    outline: none;
    border-radius: 9px;
    padding: 15px 15px 15px 15px;
}

.inquiry-link-box:focus {
	border: 2px solid var(--blue);
	box-shadow:  inset 2px 2px 4px rgba(99, 221, 255, 0.3), inset -2px -2px 4px rgba(99, 221, 255, 0.3);
}

.inquiry-link-box:hover {
  cursor: pointer;
}

.client-inquiry-or {
	width: 85%;
	text-align: center;
	border-bottom: 1px solid var(--blue);
	line-height: 0.1em;
	margin: 10px 0 20px;
}

.client-inquiry-or span {
	 background:#fff;
	 padding:0 10px;
}

/*
//on scroll stick to top table <thead>
.sticky_top{
  position: fixed;
  top: 65px;
  width: calc(100% - 2rem);
  transition: all 0.5s ease;
  animation: smoothScrolltoTop 1s forwards;
}
@keyframes smoothScrolltoTop {
  0% {
    transform: translateY(-142px);
  }

  100% {
    transform: translateY(0px);
  }
}
.pt-92{
  padding-top:136px;
}
*/

.dropdown .dropdown-toggle.btn.btn-secondary {
    color: #6c757d;
    background-color: var(--silver);
    border-color: #6c757d;
    border-radius: 36px !important;
    border: 1px solid #6c757d;
    padding: 7px;
}

/* Responsive Media Queries */
@media only screen and (max-width: 1500px) {
    .search-bar input {
        min-width: auto;
    }
    .search-bar {
        width: 250px;
    }
    .search-bar input,
    .apartment-area label,
    .apartment-area .css-2b097c-container {
        font-size: 1rem;
    }
    .css-yk16xz-control,
    .css-1pahdxg-control,
    .filters input {
        font-size: 0.9rem;
    }
    .modal-dialog.edit-popup {
        width: 80%;
    }
}

@media only screen and (max-width: 1300px) {
    .modal-dialog.edit-popup {
        width: 90%;
    }
    .admin-header .navbar .nav-item a {
        padding: 0.2rem 1rem;
    }
}

@media only screen and (max-width: 1200px) {
    .btn.sidebar-right-button.right-positioned {
        right: 1.5rem !important;
        border-radius: var(--rounded-medium) !important;
        background: rgba(255, 255, 255, 0.8);
        z-index: 1501;
        transition: 0.3s;
    }
    .btn.sidebar-right-button svg {
        margin-right: 0;
    }
}

@media only screen and (max-width: 991px) {
    .css-2b097c-container {
        width: auto;
    }
    .pl-245 {
        padding-left: 15px !important;
    }
    .navbar-logo {
        background: transparent !important;
    }
    .slider {
        max-height: 400px;
    }
    .fullpage-navigation {
        display: block;
    }
}

@media only screen and (max-width: 768px) {
    .navbar-logo {
        color: var(--white) !important;
        background: transparent;
    }
    /* .navbar .nav-item a {
        color: var(--white);
    } */
    .apartment-area label {
        color: var(--dark-blue);
    }
    .search-bar input {
        min-width: auto;
    }
    .card-body {
        padding: 0.6rem !important;
    }
    .sidebar-right.md {
        width: 100%;
        height: 100%;
        right: -100%;
        z-index: 1500;
    }
    .slider {
        max-height: 300px;
    }
    .btn.sidebar-right-button.right-positioned {
        top: 1.5rem;
    }
}

@media only screen and (max-width: 767px) {
    .css-2b097c-container {
        /* width: 100%; */
        border-color: var(--gray);
    }
}

@media only screen and (max-width: 600px) {
    .property-card-image {
        height: auto;
        max-height: 170px;
    }
    .user-profile-bottom p {
        font-size: 12px;
    }
}

@media only screen and (max-width: 575px) {
    .property-report-image {
        height: 320px;
    }
    .cols-dropdown.btn-group {
        right: 0px;
        top: -118px;
    }
    .default-cols {
        right: 295px;
        top: -128px;
    }

    /* only menu popups scroll for mobile device*/
    .filter-bar .search-bar {
        flex: inherit;
        width: 90px !important;
    }
    .applied-filters.filters {
        overflow-x: scroll;
    }

    /* full width scroll for mobile device*/
    /*.filter-bar {
        overflow-x: scroll;
    }
    .filter-bar .search-bar {
        overflow: visible;
    }*/

    .applied-filters .filter-box {
        position: fixed !important;
        top: auto !important;
        min-width: auto !important;
        margin-left: 0 !important;
    }
}

@media only screen and (max-width: 450px) {
    .hide-lg-mobile {
        display: none;
    }
    .more-prices .card-body {
        padding-right: 3rem !important;
    }
    .more-prices .card-body>.client-rating-notes-icon {
        top: 0.5rem;
    }
		#clientInquiryForm h4 {
			text-align: center;
		}
}

@media only screen and (max-width: 410px) {
    .hide-mobile {
        display: none;
    }
}