.filter-bar.mr-3 .search-bar {
    max-width: inherit !important;
    width: 100% !important;
}

.filterIcon {
    border-radius: 80px;
    color: var(--blue-900);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: #fff;
    min-width: 66px;
    width: 66px;
    height: 66px;
    cursor: pointer;
}
.modal {
    z-index: 1500 !important;
}
.modal-fullscreen {
    /* width: 100% !important;
    max-width: none !important;
    height: 100% !important; */
    margin: 0;
}
.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
}
.modal-footer-sticky {
    position: sticky;
    bottom: 0;
    background-color: inherit;
    z-index: 1055;
}
.modal-footer button {
    width: 29%;
}
.react-datepicker {
    display: inherit !important;
}
.react-datepicker__month-container {
    float: none !important;
}
.filter-bar-calendar .react-datepicker__day, .filter-bar-calendar .react-datepicker__day-name {
    width: 13% !important;
}

.table-fixed {
    width: 100%;
}
.table-fixed thead {
    display: table;
    width: 100%;
}
.table-fixed tbody {
    /* height: 750px;
    overflow-y: auto;
    display: block;
    table-layout: fixed;
    width: 100%; */

    overflow: auto;
    display: block;
    width: 100%;
    height: 54vh;
    table-layout: fixed;
}
.apartment-table .table thead th {
    width: calc(100%/12);
}
.apartment-table table td {
    width: calc(100%/12);
}
/* .apartment-table table th:first-child, .apartment-table table td>table td:first-child, .apartment-table table td:first-child {
    width: 60px;
    max-width: 60px;
    min-width: 60px;
} */
/* .apartment-table .table tbody tr {
    display: table;
    table-layout: fixed;
    width: 100%;
} */
.fixed-position-box {
    height: 750px;
    overflow-y: auto;
    position: relative;
}
.property-report-map {
    top: 0;
    left: 0;
}
@media only screen and (max-width: 1600px) {
    .modal-content .filter-box {
        margin-left: 0 !important;
    }
}
